import React, { FC } from 'react';

import cn from 'classnames';
import { IAppClassNameFromState } from './AppClassName.types';

const App: FC<IAppClassNameFromState> = ({
  children,
  isBigDesktop,
  isDesktop,
  isMobile,
  isSmallMobile,
  isTablet,
}) => (
  <div
    className={cn({
      bigDesktop: isBigDesktop,
      desktop: isDesktop,
      mobile: isMobile,
      smallMobile: isSmallMobile,
      tablet: isTablet,
    })}
  >
    {children}
  </div>
);

export default App;
