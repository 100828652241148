import React from 'react';

import cn from 'classnames';

import config from '@Config';
import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import styles from './OverbookingTimeSlots.module.scss';
import { IOverbookingTimeSlotsProps } from './OverbookingTimeSlots.types';

const COME_ON_TIME_TEXT = _L(LOCALS.REMEMBERS_TEXT);

const exclamationUrl = config.theme.isHangar
  ? '/static/exclamation_black.svg'
  : '/static/exclamation.svg';

const OverbookingTimeSlots = ({
  isSmallScreen,
  setSlot,
  timeSlots,
}: IOverbookingTimeSlotsProps) => (
  <div className={cn(styles.wrapper)}>
    {!isSmallScreen && timeSlots && timeSlots.length ? (
      <div className={styles.info}>
        <img className={styles.infoIcon} src={exclamationUrl} alt="" />
        {COME_ON_TIME_TEXT}
      </div>
    ) : null}
    <ul className={cn(styles.timeSlots, 'S')}>
      <li className={styles.row}>
        {timeSlots.map((row) =>
          row.slots.map((timeSlot) =>
            !timeSlot.isDisabled ? (
              <button
                className={cn(
                  styles.button,
                  timeSlot.isSelected && styles.selected,
                )}
                disabled={timeSlot.isDisabled}
                key={timeSlot.start}
                /* tslint:disable-next-line:jsx-no-lambda */
                onClick={() => setSlot(timeSlot.start)}
              >
                {getShortTime(timeSlot.start)}
              </button>
            ) : null,
          ),
        )}
      </li>
    </ul>
    {isSmallScreen && timeSlots && timeSlots.length ? (
      <div className={styles.info}>
        <img className={styles.infoIcon} src={exclamationUrl} alt="" />
        {COME_ON_TIME_TEXT}
      </div>
    ) : null}
  </div>
);

export default OverbookingTimeSlots;
