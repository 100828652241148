import React from 'react';

import EntryTerm from '@Compo/CustomByClient/Hangar/EntryTerm';
import Grid from '@Compo/layout/Grid';
import PageWrapper from '@Compo/layout/PageWrapper';

const HappeningPage = () => (
  <Grid>
    <PageWrapper>
      <EntryTerm />
    </PageWrapper>
  </Grid>
);

export default HappeningPage;
