import moment from 'moment';
import { createSelector } from 'reselect';

import { addTimeWithMoment } from '@Misc/helpers/dateTime/addTime';
import {
  getActivityDuration,
  getConfigurationForSelectedSpace,
} from '@Model/booking/selector/index';
import getValues from './getValues';

const MORNING = 'rana'; // polish verb form  "until morning" ;

const calculateUpSellProps = createSelector(
  [getValues, getConfigurationForSelectedSpace, getActivityDuration],
  (values, configuration, duration) => {
    if (!(values.slot && configuration && duration)) {
      return {
        additionalDuration: 0,
        finishTime: '',
        isUpSellSelected: false,
        upSellPrice: 0,
      };
    }

    const regularPrice = (configuration && configuration.prices[0].value) || 0;
    const price = (configuration.upsell && configuration.upsell.price) || 0;
    const time = values.slot ? values.slot : '';
    const eventStartTime = moment(time, 'HH:mm');
    const doubleDurationTime = duration ? 2 * duration : 0;

    return {
      additionalDuration: duration,
      /*
       * TODO: this is hack. Now we know that upsell is only for simple event.
       */
      finishTime: values.slot
        ? addTimeWithMoment(eventStartTime, doubleDurationTime)
        : MORNING,
      upSellPrice: price - regularPrice,
    };
  },
);

export default calculateUpSellProps;
