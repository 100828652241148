import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { isBigDesktop, isDesktop } from '@Model/app/selectors';
import { getConfigurationForSelectedSpace } from '@Model/booking/selector';
import calculateUpSellProps from '@Model/booking/selector/calculateUpSellProps';
import { selectProduct, unSelectProduct } from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors/index';
import { setUpSell } from '@Model/reservation/actions';
import { isUpSellEnabled } from '@Model/reservation/selectors';
import _Store from '@Store';
import UpSell from './UpSell.component';
import { IUpSellFromDispatch, IUpSellFromState } from './UpSell.types';

const mapStateToProps = (state: _Store.IState): IUpSellFromState => {
  const values = calculateUpSellProps(state);
  const configuration = getConfigurationForSelectedSpace(state);

  return {
    ...values,
    haveUpSell: !!(configuration && configuration.upsell),
    isBigDesktop: isBigDesktop(state),
    isDesktop: isDesktop(state),
    isUpSellSelected: isUpSellEnabled(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IUpSellFromDispatch => ({
  checkUpSell: () => dispatch(setUpSell(true)),
  resetUpSell: () => dispatch(setUpSell(false)),
});

export default connect<
  IUpSellFromState,
  IUpSellFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(UpSell);
