import { Formik } from 'formik';
import React, { useEffect } from 'react';

import { makeValidationSchema } from '@Compo/BookForm/BookForm.selectors';
import { IBookFormProps } from '@Compo/BookForm/BookForm.types';
import Loading from '@Compo/layout/Loading';
import FormLayout from './components/FormLayout';

const BookForm = ({
  initialValues,
  mounted,
  saveData,
  isLoading,
}: IBookFormProps) => {
  useEffect(() => {
    mounted();
  }, []);

  const validationSchema = makeValidationSchema();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={saveData}
      initialValues={initialValues}
      validationSchema={validationSchema}
      component={FormLayout}
    />
  );
};

export default BookForm;
