import { combineEpics } from 'redux-observable';

// Import modules
import * as App from '@Model/app/epics';
import * as Booking from '@Model/booking/epics';
import * as Errors from '@Model/errors/epics';
import * as Happening from '@Model/happening/epics';
import * as Happenings from '@Model/happenings/epics';
import * as Iframe from '@Model/iframe/epics';
import * as Price from '@Model/price/epics';
import * as Products from '@Model/products/epics';
import * as State from '@Model/state/epics';
import * as Transaction from '@Model/transaction/epics';

/* @@STORE_COMPONENT@@ */
export default combineEpics(
  // App
  App.whenSizesChangedSetDevice,
  App.asyncRedirectWhenAction,

  // Booking
  Booking.whenDayIsSetGetAvailabilities,
  Booking.whenGetAvailabilitiesRequested,
  Booking.whenHappeningMountedSetDay,
  Booking.whenReservationMounted,
  Booking.whenReservationMountedGetRegulations,
  Booking.catchSetTermsWhenAction,
  Booking.whenGetHappeningCheckTimeIsBetweenMidnightAndTime,
  Booking.whenReservationMountedRunFbqAction,

  // Errors
  Errors.addErrorWhenRequested,
  // Errors.goBackWhenRemoveErrorRequest,

  // Iframe
  Iframe.setStartStateWhenAppMounted,
  Iframe.runResizeMethodWhenSetDevice,
  Iframe.pingToApiWhenMounted,
  Iframe.captureIframeEventWhenPostMessage,
  Iframe.setRoomWhenRequest,
  Iframe.scrollToTopIframeWhenActions,
  Iframe.scrollToTopIframeWhenSummaryMounted,
  Iframe.redirectToTryAgainWhenAction,
  Iframe.scrollIframeToIframeWhenActions,
  Iframe.refreshPageWhenAction,
  Iframe.runFnqActionWhenAction,

  // Happenings
  Happening.whenHappeningMounted,
  Happening.whenHappeningRequested,
  Happening.redirectToHappeningWhenAction,
  Happening.changeHappening,
  Happening.scrollToWhenAction,
  Happening.scrollToSecondStepWhenAction,

  // Happenings
  Happenings.whenHappeningsMounted,
  Happenings.whenHappeningsRequested,

  // Price
  Price.requestForDiscountCheckOnCalculateDiscount,
  Price.requestForDiscountCheckOnCalculateUpsell,
  Price.checkDiscountWhenRequested,
  Price.displayErrorMessageWhenCheckPriceFailure,
  Price.catchCheckPriceWhenReservationStateHasBeChanged,

  // State
  State.whenAvailabilitiesFinishedResetLoading,
  State.whenAvailabilitiesRequestedSetLoading,
  State.whenHappeningFinishedResetLoading,
  State.whenHappeningRequestedSetLoading,
  State.whenHappeningsFinishedResetLoading,
  State.whenHappeningsRequestedSetLoading,
  State.setLoaderPositionWhenBookingSendRequested,
  State.setLoaderWhenBookingSendFinished,
  State.setUpStateWhenCompanyDataRequested,
  State.setLoaderWhenCompanyDataFinished,
  State.whenGetTransationDetailsFinishedResetLoading,
  State.whenGetTransactionDetailsSetLoading,

  // Transaction
  Transaction.captureTransactionDetailsWhenSummaryMounted,
  Transaction.runFbqActionWhenSummaryMounted,
  Transaction.captureTransactionDetailsWhenRequest,
  Transaction.captureTransactionEmpikDetailsWhenRequest,
  Transaction.postTransactionWhenRequested,
  Transaction.showErrorMessageWhenBookingFailed,
  Transaction.redirectToPayIWhenTransactionPosted,
  Transaction.getCompanyDataWhenRequest,
  Transaction.catchCompanyDataWhenRequest,

  // Products
  Products.getProductsWhenRequest,
  Products.whenSummaryMountedGetProducts,
  Products.selectProductWhenRequest,
  Products.unSelectAllProductsWhenRequest,
  Products.unSelectProductWhenRequest,
  Products.getProductsWhenCarnetsMounted,
  Products.setProductCountWhenRequest,
  Products.redirectToReservationWhenSelectedCarnet,
);
