export const checkUserExist = (
  url: string,
  name: string,
  email: string,
  entryId: string,
  callBack: () => void,
  stopLoading: () => void,
) => {
  const body = {
    email,
    entryId,
    name,
  };

  fetch(url, {
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
    .then((response: any) => response.json())
    .then((response: boolean) => {
      if (response && typeof response === 'boolean') {
        callBack();
      } else {
        stopLoading();
      }
    })
    .catch(() => {
      stopLoading();
    });
};

export const sendSms = (
  smsGateWay: string,
  phoneNumber: number,
  entryCode: number,
  smsText: string,
) => {
  const data = {
    message: `KOD: ${entryCode} ${smsText}`,
    receiver: phoneNumber,
  };

  fetch(smsGateWay, {
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
    mode: 'no-cors',
  }).then((response) => {
    return response.json();
  });
};
