import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IAvailabilitiesReducer } from '@Services/$availabilities-api/types';
import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  REDIRECT_TO_TERMS,
  RESET_ALL,
  RESET_SPACE_AND_SLOT,
  SET_DAY,
  SET_DURATION_TIME_AFTER_MIDNIGHT,
  SET_NUMBER_OF_PLAYERS,
  SET_SLOT,
  SET_SPACE,
} from './../constants/actions';
import { IBookingPayload, IBookingSetDayPayload } from './../types';

export const getAvailabilities = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<IBookingPayload, IAvailabilitiesReducer, Error>();

export const setDay = createStandardAction(SET_DAY)<IBookingSetDayPayload>();

export const setNumberOfPlayers = createStandardAction(SET_NUMBER_OF_PLAYERS)<
  number
>();

export const setSlot = createStandardAction(SET_SLOT)<string>();

export const setSpace = createStandardAction(SET_SPACE)<number>();

export const redirectToTerms = createStandardAction(REDIRECT_TO_TERMS)();

export const resetSpaceAndSlot = createStandardAction(RESET_SPACE_AND_SLOT)();

export const resetAll = createStandardAction(RESET_ALL)();

export const setDurationTimeAfterMidnight = createStandardAction(
  SET_DURATION_TIME_AFTER_MIDNIGHT,
)();
