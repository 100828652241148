import React from 'react';

import cn from 'classnames';
import pl from 'date-fns/locale/pl';
import DatePicker, { registerLocale } from 'react-datepicker';

import { ICalendarProps } from './Calendar.types';

import styles from './Calendar.module.scss';

registerLocale('pl', pl);

const Calendar = ({
  isDateSelectionAvailable,
  selectedDate,
  setDay,
  happeningEndDate,
  happeningStartDate,
}: ICalendarProps) => {
  return (
    <>
      <div
        className={cn(
          styles.datePicker,
          !isDateSelectionAvailable && styles.disabled,
        )}
      >
        <DatePicker
          fixedHeight={true}
          minDate={happeningStartDate || undefined}
          maxDate={happeningEndDate || undefined}
          inline={true}
          locale="pl"
          onChange={setDay}
          selected={selectedDate}
          // tslint:disable-next-line: jsx-no-lambda
          formatWeekDay={(nameOfDay: Date): string =>
            nameOfDay.toString().substr(0, 1)
          }
          useWeekdaysShort={true}
        />
      </div>
    </>
  );
};

export default Calendar;
