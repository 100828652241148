import { getType } from 'typesafe-actions';

import {
  setEnterFromUrl,
  setExternalFormUrl,
  setIframeSizes,
  setParentUrl,
  setPartnerId,
  setPassCode,
  setRoomUrl,
} from '../actions';
import { IAction, IIframeReducer } from '../types';

const MIN_IFRAME_HEIGHT = '200px';

const initialState: IIframeReducer = {
  enterFromUrl: '',
  externalFormUrl: null,
  iframeHeight: MIN_IFRAME_HEIGHT,
  parentUrl: '',
  partnerId: null,
  passCode: '',
  roomUrl: '',
};

const appSettingsReducer = (
  state: IIframeReducer = initialState,
  action: IAction,
): IIframeReducer => {
  switch (action.type) {
    case getType(setParentUrl):
      return {
        ...state,
        parentUrl: String(action.payload) || '',
      };
    case getType(setPartnerId):
      return {
        ...state,
        partnerId: action.payload,
      };
    case getType(setRoomUrl):
      return {
        ...state,
        roomUrl: String(action.payload) || '',
      };
    case getType(setEnterFromUrl):
      return {
        ...state,
        enterFromUrl: action.payload || '',
      };
    case getType(setPassCode):
      return {
        ...state,
        passCode: action.payload || '',
      };

    case getType(setIframeSizes):
      return {
        ...state,
        iframeHeight: action.payload || MIN_IFRAME_HEIGHT,
      };
    case getType(setExternalFormUrl):
      return {
        ...state,
        externalFormUrl: action.payload,
      };

    default:
      return state;
  }
};

export default appSettingsReducer;
