import React, { FC } from 'react';

import cn from 'classnames';
import styles from './Error.module.scss';
import { IErrorProps } from './Error.types';

const Error: FC<IErrorProps> = ({ remove, error }) => {
  if (!error) {
    return null;
  }
  const { text } = error;

  return (
    <div className={styles.errorBody}>
      <div className={styles.inner}>
        {text}

        <button className={cn(styles.button)} onClick={remove}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default Error;
