import React from 'react';

import config from '@Config';
import { IBackButtonProps } from './BackButton.types';

import styles from './BackButton.module.scss';

const GO_BACK_TEXT = 'Powrót do poprzedniego kroku';

const backButtonIconUrl = config.theme.isHangar
  ? '/static/arrow-left-black.svg'
  : '/static/arrow-left.svg';

const BackButton = ({ goBack }: IBackButtonProps) => (
  <button type="button" className={styles.backButton} onClick={goBack}>
    <img src={backButtonIconUrl} alt={GO_BACK_TEXT} />
  </button>
);

export default BackButton;
