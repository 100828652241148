// State properties
export const _LOADING = 'loading';

// Modules
export const MODULE_AVAILABILITIES = 'availabilities';
export const MODULE_HAPPENING = 'happening';
export const MODULE_HAPPENINGS = 'happenings';
export const MODULE_BOOKING = 'booking';
export const MODULE_INVOICE = 'invoice';
export const MODULE_TRANSACTION_DETAILS = 'transaction_details';
