import HttpError from '@Misc/classes/HttpError';

const catchHttpError = (error: any) => {
  if ('request' in error && error.request && 'status' in error.request) {
    return new HttpError('Http Error', error.request.status);
  }

  return error;
};

export default catchHttpError;
