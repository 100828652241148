import moment from 'moment';
import { connect } from 'react-redux';

import {
  getSelectedDayIncludeTimeSlot,
  getSelectedNumberOfPlayers,
} from '@Model/booking/selector';
import { get as getHappening } from '@Model/happening/selectors';
import { getProducts } from '@Model/products/selectors/index';
import calculateSummaryProps from '@Model/reservation/selectors/calculateSummaryProps';
import _Store from '@Store';
import SummaryBox from './SummaryBox.component';
import { ISummaryBoxFromState } from './SummaryBox.types';

const mapStateToProps = (state: _Store.IState): ISummaryBoxFromState => {
  const happening = getHappening(state);
  const { selectedCarnet } = getProducts(state);

  const getPropsBySummary = () => {
    if (happening && happening.metadata && happening.metadata.length) {
      return {
        happeningTitle: happening.metadata[0].title,
        ...calculateSummaryProps(state),
      };
    } else if (selectedCarnet && selectedCarnet.name) {
      const currentDate = moment().format('DD-DD-YYYY');

      return {
        carnet: true,
        happeningTitle: selectedCarnet.name,
        price: selectedCarnet.price,
        selectedTimeRange: currentDate,
      };
    }
    return {
      happeningTitle: '',
      price: 0,
      selectedTimeRange: '',
    };
  };

  return {
    ...getPropsBySummary(),
    isOverbookingAllowed: happening
      ? happening.allowNextSlotWhenOverbooked
      : false,
    selectedDay: getSelectedDayIncludeTimeSlot(state) || new Date(),
    selectedPeopleCount: getSelectedNumberOfPlayers(state),
  };
};

export default connect<ISummaryBoxFromState, null, {}, _Store.IState>(
  mapStateToProps,
)(SummaryBox);
