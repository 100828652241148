import { combineReducers } from 'redux';
import {
  MODULE_AVAILABILITIES,
  MODULE_BOOKING,
  MODULE_HAPPENING,
  MODULE_HAPPENINGS,
  MODULE_INVOICE,
  MODULE_TRANSACTION_DETAILS,
} from './../constants/constants';

import createSubStateReducer from './createSubStateReducer';

export const reducers = {
  [MODULE_AVAILABILITIES]: createSubStateReducer(MODULE_AVAILABILITIES),
  [MODULE_BOOKING]: createSubStateReducer(MODULE_BOOKING),
  [MODULE_HAPPENING]: createSubStateReducer(MODULE_HAPPENING),
  [MODULE_HAPPENINGS]: createSubStateReducer(MODULE_HAPPENINGS),
  [MODULE_INVOICE]: createSubStateReducer(MODULE_INVOICE),
  [MODULE_TRANSACTION_DETAILS]: createSubStateReducer(
    MODULE_TRANSACTION_DETAILS,
  ),
};

export default combineReducers(reducers);
