import getValues from '@Model/booking/selector/getValues';
import { get } from '@Model/happening/selectors';
import moment from 'moment';
import { createSelector } from 'reselect';
import getAvailabilities from './getAvailabilities';

const getTimeSlots = createSelector(
  [get, getAvailabilities, getValues],
  (happening, availabilities, values) => {
    if (!happening || !availabilities) {
      return [];
    }

    let dayAsString: string;

    if (values && values.day) {
      dayAsString = moment(values.day).format('YYYY-MM-DD');
    }

    const now = moment.utc().valueOf();

    let hours = Object.keys(availabilities.currentDay || {}).sort((a, b) =>
      a.localeCompare(b),
    );

    let mergedDays = availabilities.currentDay;

    if (availabilities.otherDays) {
      hours = [
        ...hours,
        ...Object.keys(availabilities.otherDays).sort((a, b) =>
          a.localeCompare(b),
        ),
      ];

      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    const result = hours.map((hour) => {
      const spaces = mergedDays[hour];

      const slotDateTime = moment(`${dayAsString} ${hour}`)
        .utc()
        .valueOf();

      let disableByTime =
        now > slotDateTime && !!availabilities.currentDay[hour];

      if (values.day) {
        const isPassDate = moment(values.day).diff(moment(), 'days') < 0;

        if (
          isPassDate &&
          availabilities &&
          availabilities.otherDays &&
          !!availabilities.otherDays[hour]
        ) {
          disableByTime =
            now >
            moment(slotDateTime)
              .add(1, 'days')
              .valueOf();
        }
      }

      const canBook = spaces.some((space) => {
        if (
          values &&
          values.numberOfPlayers &&
          space.capacityLeft &&
          values.numberOfPlayers &&
          !happening.allowNextSlotWhenOverbooked
        ) {
          const isNumberOfPeopleIsAllowed =
            values &&
            values.numberOfPlayers &&
            space.capacityLeft &&
            values.numberOfPlayers <= space.capacityLeft;

          return isNumberOfPeopleIsAllowed && space.available;
        }

        return space.available;
      });

      const canReserved = spaces.reduce((prev, curr) => {
        return prev || curr.reserved;
      }, false);

      return {
        isDisabled: !canBook || disableByTime,
        isReserved: canReserved,
        isSelected: hour === values.slot,
        start: hour,
      };
    });

    if (!happening.calculatePricePerPerson) {
      return result;
    }

    if (
      happening &&
      happening.allowNextSlotWhenOverbooked &&
      happening.spaces &&
      happening.spaces.length > 0
    ) {
      const maxNumberOfPeople = happening.spaces[0].maxNumberOfPeople;

      const howManySlots = Math.ceil(
        values.numberOfPlayers / maxNumberOfPeople,
      );

      if (howManySlots < 2) {
        return result;
      }

      return result.map((_slot, index) => {
        if (_slot.isDisabled) {
          return _slot;
        }

        let isEnabled = true;

        for (let i = 0; i < howManySlots; i++) {
          const j = index + i;

          if (j >= result.length) {
            isEnabled = false;

            break;
          }

          isEnabled = isEnabled && !result[j].isReserved;
        }

        return {
          ..._slot,
          isDisabled: !isEnabled,
        };
      });
    }

    return result;
  },
);

export default getTimeSlots;
