import { isJsonString } from '@Misc/helpers/strings/isJsonString';
import { IEvent, IFbqAction } from '@Model/iframe/types';
import { IApiAction, IAvailableFunctions, IFrameAction } from './types';

const TYPE_GOING = 'GOING';

export class IframeProvider {
  public static addEventListenerMethod = (
    messageEvent: MessageEvent,
    callBack: (event: IEvent) => void,
  ) => {
    if (messageEvent.data && isJsonString(messageEvent.data)) {
      const event = JSON.parse(messageEvent.data);

      if (
        event.type === TYPE_GOING &&
        messageEvent.origin !== window.location.origin
      ) {
        callBack(event.action);
      }
    }
  };

  public runRedirectParentMethod = (url: string) => {
    const action: IApiAction = {
      payload: url,
      type: IAvailableFunctions.REDIRECT,
    };

    this.sendMessage(action);
  };

  public runResizeMethod = (size: string) => {
    const action: IApiAction = {
      payload: size,
      type: IAvailableFunctions.RESIZE,
    };

    this.sendMessage(action);
  };

  public runScrollTopMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.SCROLL_TO_TOP,
    };
    setTimeout(() => this.sendMessage(action), 1000);
    setTimeout(() => this.sendMessage(action), 2000);

    this.sendMessage(action);
  };

  public runScrollTopMethodWithOutAsync = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.SCROLL_TO_TOP,
    };

    this.sendMessage(action);
  };

  public runScrollIframeToMethod = (top: number) => {
    const action: IApiAction = {
      payload: top.toString(),
      type: IAvailableFunctions.SCROLL_IFRAME_TO,
    };

    this.sendMessage(action);
  };

  public runSetLoaderPositionTopMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.LOADER_POSITION_TOP,
    };

    this.sendMessage(action);
  };

  public pingMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.PING,
    };

    this.sendMessage(action);
  };

  public pongMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.PONG,
    };

    this.sendMessage(action);
  };

  public setParentUrlMethod = (payload: string) => {
    const action: IApiAction = {
      payload,
      type: IAvailableFunctions.SET_PARENT_URL,
    };

    this.sendMessage(action);
  };

  public refreshPageMethod = () => {
    const action: IApiAction = {
      type: IAvailableFunctions.REFRESH_PAGE,
    };

    this.sendMessage(action);
  };

  public runFbqMethod = (payload: IFbqAction) => {
    const action: IApiAction = {
      payload: JSON.stringify(payload),
      type: IAvailableFunctions.FBQ,
    };

    this.sendMessage(action);
  };

  private sendMessage = (action: IApiAction) => {
    const targetOrigin = '*';
    const iframeAction: IFrameAction = {
      action,
      type: 'GOING',
    };

    window.parent.postMessage(JSON.stringify(iframeAction), targetOrigin);
  };
}

export default new IframeProvider();
