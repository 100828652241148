import { IBookingTimeSlot } from '@Model/booking/types';
import { createSelector } from 'reselect';
import getTimeSlots from './getTimeSlots';

const prepareResultArray = (slots: IBookingTimeSlot[]) => {
  let firstEnable = -1;

  for (let i = 0; i < slots.length; i++) {
    if (!slots[i].isDisabled) {
      firstEnable = i;

      break;
    }
  }

  if (firstEnable < 0) {
    return [];
  }

  return slots.slice(0, firstEnable + 1);
};

const getGroupedTimeSlots = createSelector(
  [getTimeSlots],
  (slots) => {
    let key = 'nil';
    const keys: string[] = [];
    const result: IBookingTimeSlot[][] = [];

    slots.forEach((_slot) => {
      const hour = _slot.start.substr(0, 2);

      if (hour !== key) {
        result.push([]);

        key = hour;
        keys.push(hour);
      }

      result[result.length - 1].push(_slot);
    });

    let remove = true;

    return result
      .map((_result, index) => ({
        hour: `${keys[index]}:00`,
        slots: prepareResultArray(_result),
      }))
      .filter((_result) => {
        if (_result.slots.length < 1) {
          return !remove;
        } else {
          remove = false;

          return true;
        }
      });
  },
);

export default getGroupedTimeSlots;
