import routes from '@/routes/routes';
import { resetAll } from '@Model/booking/actions';
import { IHappening, IHappeningRouterParams } from '@Model/happening/types';
import { scrollIframeTo } from '@Model/iframe/actions';
import { resetPrice } from '@Model/price/actions';
import _Store from '@Store';
import {
  createMatchSelector,
  LOCATION_CHANGE,
  push,
  RouterRootState,
} from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';
import { get } from '../selectors';
import {
  getHappening,
  handleHappening,
  happeningMounted,
  redirectToHappening,
  resetState,
  scrollTo,
  scrollToSecondStep,
} from './../actions';

const PLAYERS_COUNT_ID = 'playerCount';

export const whenHappeningMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(happeningMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        IHappeningRouterParams
      >(routes.happening);

      const actions = [];
      const happening = get(state);

      if (state.router.action.toLowerCase() === 'push') {
        actions.push(resetAll());
      }

      const match = matchSelector(state);

      if (match && match.params) {
        const { slug } = match.params;

        actions.push(resetState());
        actions.push(getHappening.request(slug));
      }

      return actions;
    }),
  );
};

export const changeHappening: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(handleHappening)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const actions = [];

      if (state.router.action.toLowerCase() === 'push') {
        actions.push(resetAll());
      }

      actions.push(resetState());
      actions.push(getHappening.request(action.payload));

      return actions;
    }),
  );
};

export const whenHappeningRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.request)),
    mergeMap$((action) => {
      return from$(happeningsApi.getHappening(action.payload)).pipe(
        mergeMap$((data: IHappening) => {
          return [
            resetPrice(),
            getHappening.success(data),
            scrollToSecondStep(),
            scrollTo(PLAYERS_COUNT_ID),
          ];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => happeningsApi.cancelSingleHappening()),
          ),
        ),
        catchError$((error: Error) => of$(getHappening.failure(error))),
      );
    }),
  );
};

export const redirectToHappeningWhenAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(redirectToHappening)),
    mergeMap$((action) => {
      return of$(push(action.payload));
    }),
  );
};

export const scrollToSecondStepWhenAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(scrollToSecondStep)),
    mergeMap$(() => {
      const secondStep = document.getElementById('step2');

      if (secondStep && secondStep.offsetTop) {
        return of$(scrollIframeTo(secondStep.offsetTop));
      }

      return EMPTY$;
    }),
  );
};

export const scrollToWhenAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(scrollTo)),
    mergeMap$((action) => {
      const element = document.getElementById(action.payload);

      // if (element && element.offsetTop) {
      //   return of$(scrollIframeTo(element.offsetTop));
      // }

      return EMPTY$;
    }),
  );
};
