import { IAvailabilitiesReducer } from '@Services/$availabilities-api/types';
import { getType } from 'typesafe-actions';
import { getAvailabilities } from './../actions';
import { IAction } from './../types';

const initialState: IAvailabilitiesReducer = { currentDay: {} as any };

const availabilitiesReducer = (
  state: IAvailabilitiesReducer = initialState,
  action: IAction,
): IAvailabilitiesReducer => {
  switch (action.type) {
    case getType(getAvailabilities.success):
      return action.payload;

    default:
      return state;
  }
};

export default availabilitiesReducer;
