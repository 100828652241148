import api from './api';
import resizer from './resizer';
import theme from './theme';

const config = {
  api,
  resizer,
  theme,
};

export default config;
