/*
 * Name convention: MODULE__LOCAL_NAME
 */
export const SELECT_PLACE_TEXT = 'SELECT_PLACE_TEXT';
export const SELECT_PLAYERS_TEXT = 'SELECT_PLAYERS_TEXT';
export const REMEMBERS_TEXT = 'REMEMBERS_TEXT';
export const RESERVATION_TEXT = 'RESERVATION_TEXT';
export const INTRO_RESERVATION_TEXT = 'INTRO_RESERVATION_TEXT';

export default {
  INTRO_RESERVATION_TEXT,
  REMEMBERS_TEXT,
  RESERVATION_TEXT,
  SELECT_PLACE_TEXT,
  SELECT_PLAYERS_TEXT,
};
