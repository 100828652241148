import { ITransactionReducer } from '@Model/transaction/types';
import _Store from '@Store';
import format from 'date-fns/format';
import pl from 'date-fns/locale/pl';
import moment from 'moment';
import { createSelector } from 'reselect';
import getTransactionDetails from './getTransactionDetails';

const getTransactionDateTime = createSelector<
  _Store.IState,
  ITransactionReducer | null,
  string
>(
  [getTransactionDetails],
  (transaction: ITransactionReducer | null) => {
    if (transaction && transaction.dateTime) {
      const timezoneDateTime = moment
        .utc(transaction.dateTime)
        .format('YYYY-MM-DD HH:mm:ss');

      return `${format(moment(timezoneDateTime).toDate(), 'EEEE dd.MM.yy', {
        locale: pl,
      })} r., ${format(moment(timezoneDateTime).toDate(), 'HH:mm')}`;
    }
    return '';
  },
);

export default getTransactionDateTime;
