import React from 'react';

import { Route, Switch } from 'react-router';
import routes from './routes';

// Route components
import Error404 from '@/components/errors/Error404';
import Carnets from '@/components/pages/Carnets';
import TermsHangar from '@/components/pages/CustomByClient/TermsHangar';
import ExternalForm from '@/components/pages/ExternalForm';
import Happening from '@/components/pages/Happening';
import Main from '@/components/pages/Main';
import Reservation from '@/components/pages/Reservation';
import Start from '@/components/pages/Start';
import Summary from '@/components/pages/Summary';
import SummaryFail from '@/components/pages/SummaryFail';
// import SummaryFail from '@/components/pages/Tic';

const Routes = () => (
  <>
    <Switch>
      <Route exact={true} path={routes.summary} component={Summary} />
      <Route exact={true} path={routes.paymentFail} component={SummaryFail} />
      <Route exact={true} path={routes.index} component={Main} />
      <Route exact={true} path={routes.happening} component={Happening} />
      <Route exact={true} path={routes.reservation} component={Reservation} />
      <Route exact={true} path={routes.start} component={Start} />
      {/* <Route exact={true} path={routes.tickets} component={Tickets} /> */}
      <Route exact={true} path={routes.termsHangar} component={TermsHangar} />
      <Route exact={true} path={routes.externalForm} component={ExternalForm} />
      <Route exact={true} path={routes.carnets} component={Carnets} />
      <Route component={Error404} />
    </Switch>
  </>
);

export default Routes;
