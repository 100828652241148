import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { resetSpaceAndSlot } from '@Model/booking/actions';
import {
  getRecommendedNumberOfPlayers,
  getSelectedSpace,
} from '@Model/booking/selector';
import calculatePrice from '@Model/booking/selector/calculatePrice';
import isBookingAvailable from '@Model/booking/selector/isBookingAvailable';
import { happeningMounted, resetState } from '@Model/happening/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { happeningsMounted } from '@Model/happenings/actions';
import { get as getCheckedPrice } from '@Model/price/selectors';
import {
  MODULE_AVAILABILITIES,
  MODULE_HAPPENING,
} from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import Happening from './Happening.component';
import { IHappeningFromDispatch, IHappeningFromState } from './Happening.types';

const mapStateToProps = (state: _Store.IState): IHappeningFromState => {
  const getPrice = (): number => {
    const checkedPrice = getCheckedPrice(state);
    const calculatedPrice = calculatePrice(state);

    if (checkedPrice && checkedPrice.value) {
      return checkedPrice.value;
    } else if (calculatedPrice) {
      return calculatedPrice;
    }

    return 0;
  };

  return {
    happening: getHappening(state),
    isBookingAvailable: isBookingAvailable(state),
    isLoading: getLoading(MODULE_HAPPENING)(state),
    isLoadingAvailabilities: getLoading(MODULE_AVAILABILITIES)(state),
    price: getPrice(),
    recommendedNumberOfPlayers: getRecommendedNumberOfPlayers(state),
    selectedSpace: getSelectedSpace(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IHappeningFromDispatch => ({
  getHappenings: () => dispatch(happeningsMounted()),
  mounted: () => dispatch(happeningMounted()),
  resetSpaceAndSlot: () => dispatch(resetSpaceAndSlot()),
  unmount: () => dispatch(resetState()),
});

export default connect<
  IHappeningFromState,
  IHappeningFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Happening);
