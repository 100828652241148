import * as LOCALS from './constants';
import { ILocals } from './types';

const hangarLocals: ILocals = {
  [LOCALS.SELECT_PLACE_TEXT]: 'Wybierz aktywność',
  [LOCALS.SELECT_PLAYERS_TEXT]: 'Wybierz liczbę osób',
  [LOCALS.REMEMBERS_TEXT]:
    'Pamiętaj żeby pojawić się w Hangarze przynajmniej 20 min. przed czasem!',
  [LOCALS.RESERVATION_TEXT]: 'Podaj dane do rezerwacji',
  [LOCALS.INTRO_RESERVATION_TEXT]: 'Rezerwacja',
};

export default hangarLocals;
