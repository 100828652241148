import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { isBigDesktop, isDesktop } from '@Model/app/selectors';
import getBasketData from '@Model/basket/selector/getBasketData';
import { getConfigurationForSelectedSpace } from '@Model/booking/selector';
import { getProducts } from '@Model/products/selectors';
import { catchCompanyData, setFormData } from '@Model/reservation/actions';
import { getAdditionalTerms } from '@Model/reservation/selectors';
import { ITerm } from '@Model/reservation/types';
import { MODULE_INVOICE } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import BookForm from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const configuration = getConfigurationForSelectedSpace(state);
  const { items, selectedCarnet } = getProducts(state);
  const additionalTerms = getAdditionalTerms(state);
  const areTermsAccepted = additionalTerms.every((term: ITerm) => {
    if (term.required) {
      return !!term.selected;
    }
    return true;
  });

  return {
    areTermsAccepted,
    currentBasketData: getBasketData(state),
    haveUpSell: Boolean(configuration && configuration.upsell),
    isBigDesktop: isBigDesktop(state),
    isCarnet: !!selectedCarnet,
    isDesktop: isDesktop(state),
    isInvoiceLoading: getLoading(MODULE_INVOICE)(state),
    products: items,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFormLayoutFromDispatch => ({
  getCompanyData: (nip: string) => dispatch(catchCompanyData(nip)),
  setFormData: (data) => dispatch(setFormData(data)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(BookForm);
