import React, { useContext } from 'react';

import cn from 'classnames';
import format from 'date-fns/format';
import pl from 'date-fns/locale/pl';
import moment from 'moment';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import { priceFormatter } from '@Misc/helpers';
import capitalise from '@Misc/helpers/strings/capitalise';

import styles from './Basket.module.scss';

const START_DATE_TEXT = 'Ważny od ';
const REMOVE_SMALL_TEXT = '-';

const Basket = () => {
  const carnet = false;

  const state = useContext(clearContext);

  if ((state && !state.basketItems.length) || !state) {
    return null;
  }

  const selectedDayString = (selectedDay: string | null) => {
    if (selectedDay) {
      const date = moment(selectedDay).toDate();
      return capitalise(format(date, 'EEEE dd.MM', { locale: pl }));
    }
  };

  return (
    <div className={styles.basketWrapper}>
      {state.basketItems.map(
        ({ id, title, price, dateTime, selectedTimeRange }) => (
          <section key={id} className={styles.section}>
            <div className={styles.leftSite}>
              <section className={cn(!carnet && styles.topSection)}>
                {!carnet && (
                  <span className={styles.day}>
                    {selectedDayString(dateTime)}
                  </span>
                )}
              </section>
              <div className={styles.row}>
                <span className={cn(carnet && styles.strong)}>{title}</span>
                {!carnet && <span>{priceFormatter(price)} PLN</span>}
              </div>
              <span className={styles.text}>
                {carnet && START_DATE_TEXT}
                <span className={cn(carnet && styles.strong)}>
                  {selectedTimeRange}
                </span>
              </span>
            </div>

            <button
              className={styles.button}
              type="button"
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => state.removeFromBag(id)}
            >
              {REMOVE_SMALL_TEXT}
            </button>
          </section>
        ),
      )}
    </div>
  );
};

export default Basket;
