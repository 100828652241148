import format from 'date-fns/format';

import getMetadataForLanguage from '@/misc/helpers/getMetadataForLanguage';
import {
  getAvailabilities,
  getSelectedDayIncludeTimeSlot,
} from '@Model/booking/selector';
import calculatePrice from '@Model/booking/selector/calculatePrice';
import getValues from '@Model/booking/selector/getValues';
import { get as getHappening } from '@Model/happening/selectors';
import { IHappeningSpaceMetadata } from '@Model/happening/types';
import { IHappeningsItemMetadata } from '@Model/happenings/types';
import { get as getCheckedPrice } from '@Model/price/selectors';
import calculateSummaryProps from '@Model/reservation/selectors/calculateSummaryProps';
import { createSelector } from 'reselect';
import { IZagrywkiBasket } from '../types';

const getBasketData = createSelector(
  [
    getHappening,
    getAvailabilities,
    getValues,
    getSelectedDayIncludeTimeSlot,
    getCheckedPrice,
    calculatePrice,
    calculateSummaryProps,
  ],
  (
    happening,
    availabilities,
    values,
    day,
    checkedPrice,
    calculatedPrice,
    summaryProps,
  ) => {
    const selectedSpace =
      happening &&
      happening.spaces.find((_space) => _space.id === values.space);

    const dateTime =
      values && values.day
        ? `${format(day || values.day, 'yyyy-MM-dd')}T${values.slot}+00:00`
        : null;

    const getPrice = (): number => {
      if (checkedPrice && checkedPrice.value) {
        return checkedPrice.value;
      } else if (calculatedPrice) {
        return calculatedPrice;
      }

      return 0;
    };

    if (happening && selectedSpace) {
      const spaceMetadata = getMetadataForLanguage<IHappeningSpaceMetadata>(
        selectedSpace.metadata,
      ).title;

      const happeningMetaData = getMetadataForLanguage<IHappeningsItemMetadata>(
        happening.metadata,
      ).title;

      const getHappeningProps = (): IZagrywkiBasket => {
        return {
          dateTime,
          duration: selectedSpace.timeSlot,
          happeningId: happening.id,
          id: selectedSpace.id,
          price: getPrice(),
          selectedTimeRange: summaryProps.selectedTimeRange,
          spaceId: selectedSpace.id,
          title: `${spaceMetadata} ${happeningMetaData}`,
        };
      };

      return getHappeningProps();
    }
    return null;
  },
);

export default getBasketData;
