import React from 'react';

import cn from 'classnames';

import Checkbox from '@Compo/forms/Checkbox';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  IHappeningsItem,
  IHappeningsItemMetadata,
} from '@Model/happenings/types';
import { IHappeningCheckBoxProps } from './HappeningCheckBox.types';

import styles from './HappeningCheckBox.module.scss';

const SELECT_HANGAR_TEXT = 'Wybierz hangar';

const HappeningCheckBox = ({
  happenings,
  happening: selectedHappening,
  redirectToHappening,
  handleHappening,
}: IHappeningCheckBoxProps) => {
  const getMetadata = (happening: IHappeningsItem) =>
    getMetadataForLanguage<IHappeningsItemMetadata>(happening.metadata);

  const catchHandleHappening = (slug: string) => {
    redirectToHappening(`/wydarzenie/${slug}`);
    handleHappening(slug);
  };
  return (
    <>
      <div className={styles.title}>{SELECT_HANGAR_TEXT}</div>
      <ul className={styles.spaces}>
        {happenings.map((happening) => {
          const isSelected = !!(
            selectedHappening && selectedHappening.id === happening.id
          );

          return (
            <li className={styles.space} key={happening.id}>
              <div
                className={styles.linkBox}
                role="button"
                // tslint:disable-next-line: jsx-no-lambda
                onClick={() =>
                  catchHandleHappening(getMetadata(happening).slug)
                }
              >
                <section
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${happening.imageUrl || ''})`,
                  }}
                />

                <section
                  className={cn(
                    styles.sectionHangar,
                    isSelected && styles.selected,
                  )}
                >
                  <section className={styles.description}>
                    {getMetadata(happening).title}
                  </section>
                  <section className={styles.name}>
                    {getMetadata(happening).description}
                  </section>
                </section>

                <section className={styles.description}>
                  <Checkbox
                    checked={isSelected}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={() => null}
                  >
                    {getMetadata(happening).title}
                  </Checkbox>
                </section>
                <section className={styles.name}>
                  {getMetadata(happening).description}
                </section>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default HappeningCheckBox;
