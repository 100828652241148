import React, { useState, useContext, useEffect } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Checkbox from '@Compo/forms/Checkbox';
import FormField from '@Compo/forms/FormField';
import BackButton from '@Compo/layout/BackButton';
import Loading from '@Compo/layout/Loading';
import SummaryBox from '@Compo/SummaryBox';
import UpSell from '@Compo/UpSell';
import Config from '@Config';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import Discount from './../Discount';
import Terms from './../Terms';
import { IFormLayoutProps } from './FormLayout.types';

import styles from './FormLayout.module.scss';

const DO_YOU_HAVE_DISCOUNT_TEXT = 'Posiadasz kod zniżkowy?';
const PLAY_EVEN_BETTER_TEXT = 'Bawcie się jeszcze lepiej';
const PHONE_NUMBER_PLACEHOLDER_TEXT = '+48';
const FIRST_NAME_TEXT = 'Imię';
const LAST_NAME_TEXT = 'Nazwisko';
const EMAIL_TEXT = 'Adres e-mail';
const PHONE_TEXT = 'Numer telefonu';
const I_WANT_INVOICE_TEXT = 'Chcę otrzymać fakturę';
const I_HAVE_PASS = 'Mam karnet';

const I_PAY_TEXT = 'Płacę';
const CONTINUE_BUYING_TEXT = 'Kontynuuj zakupy';
const NIP_NUMBER_TEXT = 'NIP';
const PASS_CODE_TEXT = 'Nr karnetu';
const COMPANY_NAME_TEXT = 'Nazwa firmy';
const STREET_TEXT = 'Ulica';
const HOUSE_NUMBER_TEXT = 'Numer';
const ZIP_CODE_TEXT = 'Kod pocztowy';
const CITY_TEXT = 'Miasto';

const DATE_FOR_FACTURE_TEXT = 'Dane do faktury';

const FILL_DATA_TEXT = 'Uzupełnij dane';

const FormLayout = ({
  areTermsAccepted,
  errors,
  handleSubmit,
  haveUpSell,
  isBigDesktop,
  isDesktop,
  touched,
  values,
  products,
  setFieldValue,
  getCompanyData,
  setFormData,
  isInvoiceLoading,
  isCarnet,
  currentBasketData,
  setValues,
}: IFormLayoutProps) => {
  const BagState = useContext(clearContext);
  useEffect(() => {
    if (BagState) {
      BagState.readLocalStorage();

      if (BagState.userData) {
        setValues(BagState.userData);
      }
    }
  }, []);

  const isBookingAvailable =
    areTermsAccepted && Object.keys(errors).length === 0;
  const [timeOut, setTimeOut] = useState<number>(0);
  const { isHangar } = Config.theme;

  const isError = (fieldName: string): boolean =>
    // @ts-ignore
    touched[fieldName] && errors[fieldName] !== undefined;

  const isBiggerDesktop = isDesktop || isBigDesktop;

  const PROVIDE_BOOKING_DATA_TEXT = isCarnet
    ? FILL_DATA_TEXT
    : _L(LOCALS.RESERVATION_TEXT);

  const renderExtra = () => (
    <>
      {!isCarnet && (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>{DO_YOU_HAVE_DISCOUNT_TEXT}</h4>
          <Discount values={values} touched={touched} />
        </section>
      )}

      {haveUpSell || (products && products.length) ? (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>{PLAY_EVEN_BETTER_TEXT}</h4>
          <UpSell />
        </section>
      ) : null}
    </>
  );

  const renderFormField = (fieldName: string, text: string) => (
    <label className={styles.label} htmlFor={fieldName}>
      {text}
      <FormField
        id={fieldName}
        name={fieldName}
        type="text"
        isError={isError(fieldName)}
      />
    </label>
  );

  const setNipValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setFieldValue('nip', value);

    if (timeOut) {
      clearTimeout(timeOut as any);
    }

    setTimeOut(setTimeout(() => {
      getCompanyData(value);
      setFormData({ ...values, nip: value });
    }, 1000) as any);
  };

  const renderNipField = (fieldName: string, text: string) => (
    <label className={styles.label} htmlFor={fieldName}>
      {text}
      <FormField
        id={fieldName}
        name={fieldName}
        type="text"
        isError={isError(fieldName)}
        onChange={setNipValue}
      />
    </label>
  );

  const handleContinueBuying = (event: React.FormEvent<HTMLButtonElement>) => {
    // event.preventDefault();

    if (BagState && currentBasketData) {
      BagState.addToBag(currentBasketData);
      BagState.handleUserData(values);
    }
  };

  const { facture, pass } = values;

  return (
    <>
      <section className={styles.header}>
        <BackButton />
        <h1 className={styles.title}>{PROVIDE_BOOKING_DATA_TEXT}</h1>
      </section>

      <form className={styles.form} onSubmit={handleSubmit}>
        <section className={styles.twoColumns}>
          <div className={styles.fixedBox}>
            {!isBiggerDesktop && renderExtra()}

            <SummaryBox />
          </div>

          <fieldset className={styles.box}>
            <div className={styles.formRow}>
              <label className={styles.label} htmlFor="firstName">
                {FIRST_NAME_TEXT}
                <FormField
                  id="firstName"
                  name="firstName"
                  type="text"
                  isError={isError('firstName')}
                />
              </label>
              <label className={styles.label} htmlFor="lastName">
                {LAST_NAME_TEXT}
                <FormField
                  id="lastName"
                  name="lastName"
                  type="text"
                  isError={isError('lastName')}
                />
              </label>
            </div>

            <div className={styles.formRow}>
              <label className={styles.label} htmlFor="email">
                {EMAIL_TEXT}
                <FormField
                  id="email"
                  name="email"
                  type="email"
                  isError={isError('email')}
                />
              </label>
              <label className={styles.label} htmlFor="phoneNumber">
                {PHONE_TEXT}
                <FormField
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={PHONE_NUMBER_PLACEHOLDER_TEXT}
                  type="text"
                  isError={isError('phoneNumber')}
                  value={values.phoneNumber || ''}
                />
              </label>
            </div>

            <div className={styles.factureFormRow}>
              <div className={styles.formRow}>
                {isHangar && !isCarnet && (
                  <label className={styles.label} htmlFor="pass">
                    <Checkbox
                      checked={!!pass}
                      // tslint:disable-next-line: jsx-no-lambda
                      onChange={() => setFieldValue('pass', !pass)}
                    >
                      <span>{I_HAVE_PASS}</span>
                    </Checkbox>
                  </label>
                )}
                <label className={styles.label} htmlFor="facture">
                  <Checkbox
                    checked={!!facture}
                    // tslint:disable-next-line: jsx-no-lambda
                    onChange={() => setFieldValue('facture', !facture)}
                  >
                    <span>{I_WANT_INVOICE_TEXT}</span>
                  </Checkbox>
                </label>
              </div>
            </div>

            {pass && (
              <div className={styles.formRow}>
                {renderFormField('passCode', PASS_CODE_TEXT)}
              </div>
            )}

            {facture && (
              <>
                <div className={styles.formRow}>
                  <h3 className={styles.factureTitle}>
                    {DATE_FOR_FACTURE_TEXT}
                  </h3>
                </div>
                <div className={styles.formRow}>
                  {renderNipField('nip', NIP_NUMBER_TEXT)}

                  {!isInvoiceLoading &&
                    renderFormField('companyName', COMPANY_NAME_TEXT)}
                </div>

                {!isInvoiceLoading && (
                  <>
                    <div className={styles.formRow}>
                      {renderFormField('street', STREET_TEXT)}
                      {renderFormField('houseNumber', HOUSE_NUMBER_TEXT)}
                    </div>
                    <div className={styles.formRow}>
                      {renderFormField('zipCode', ZIP_CODE_TEXT)}
                      {renderFormField('city', CITY_TEXT)}
                    </div>
                  </>
                )}

                {isInvoiceLoading && <Loading />}
              </>
            )}
          </fieldset>
        </section>

        {isBiggerDesktop && renderExtra()}

        {!isBiggerDesktop && (
          <section className={styles.singleRow}>
            <div className={styles.formRow}>
              <Terms />
            </div>
          </section>
        )}

        <section className={cn(styles.singleRow, styles.rowButtons)}>
          {isBiggerDesktop && <Terms />}

          <Link to={'/'}>
            <button
              className={cn(styles.backButton)}
              onClick={handleContinueBuying}
            >
              {CONTINUE_BUYING_TEXT}
            </button>
          </Link>
          <button
            className={cn(
              styles.payButton,
              !isBookingAvailable && styles.disabled,
            )}
            type="submit"
          >
            {I_PAY_TEXT}
          </button>
        </section>
      </form>
    </>
  );
};

export default FormLayout;
