import { LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { setSlot, setSpace } from '@Model/booking/actions';
import {
  getConfigurationForSelectedSpace,
  getSelectedDayIncludeTimeSlot,
  getValues,
} from '@Model/booking/selector';
import { add } from '@Model/errors/actions';
import { scrollTo } from '@Model/happening/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { updateList } from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors/index';
import {
  clearDiscount,
  setDiscount,
  setUpSell,
} from '@Model/reservation/actions';
import { isUpSellEnabled } from '@Model/reservation/selectors';
import getDiscountCode from '@Model/reservation/selectors/getDiscountCode';
import { IPriceCheckBody, ISelectedProduct } from '@Services/$price-api/types';
import _Store from '@Store';
import { checkPrice } from './../actions';

const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

const PLACE_ID = 'place';

export const requestForDiscountCheckOnCalculateDiscount: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setDiscount])),
    withLatestFrom$(state$),
    map$(() => checkPrice.request('discount')),
  );
};

export const requestForDiscountCheckOnCalculateUpsell: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setUpSell])),
    withLatestFrom$(state$),
    map$(() => checkPrice.request('upsell')),
  );
};

export const checkDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { priceAPi },
) => {
  return action$.pipe(
    filter$(isActionOf(checkPrice.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const values = getValues(state);
      const configuration = getConfigurationForSelectedSpace(state);
      const date = getSelectedDayIncludeTimeSlot(state);
      const products = getProducts(state);

      if (
        !(
          values &&
          values.day &&
          values.slot &&
          values.space &&
          configuration &&
          date
        )
      ) {
        return EMPTY;
      }

      const selectedProducts = (): ISelectedProduct[] => {
        if (products && products.items && products.items.length) {
          const { items } = products;

          return items
            .filter((item) => item.selected)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || 1,
            }));
        }
        return [];
      };

      const getNumberOfPeople = (): number => {
        const happening = getHappening(state);

        if (happening) {
          if (happening.calculatePricePerPerson && values.numberOfPlayers) {
            return values.numberOfPlayers;
          }

          const selectedSpace = happening.spaces.find(
            (_space) => _space.id === values.space,
          );

          if (selectedSpace) {
            return selectedSpace.maxNumberOfPeople;
          }
        }

        return 0;
      };

      const body: IPriceCheckBody = {
        dateTime: `${format(date, 'yyyy-MM-dd')}T${values.slot}+00:00`,
        numberOfPeople: getNumberOfPeople(),
        price: configuration.prices[0].value,
        products: selectedProducts(),
        spaceId: values.space,
      };

      const discountCode = getDiscountCode(state);
      const upSellSelected = isUpSellEnabled(state);

      if (discountCode) {
        body.discount = {
          code: discountCode,
        };
      }

      if (upSellSelected) {
        body.upsell = {
          configurationId: configuration.id,
        };
      }

      return from$(priceAPi.checkPrice(body)).pipe(
        map$(checkPrice.success),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => priceAPi.cancelCheckPrice()),
          ),
        ),
        catchError$((error: string) => {
          switch (action.payload) {
            case 'discount':
              return of$(checkPrice.failure(error), clearDiscount());
            case 'upsell':
              if (upSellSelected) {
                return of$(checkPrice.failure(error), setUpSell(false));
              }
            case 'product':
              const { items } = getProducts(state);
              const newProductsList = items.map((item) => {
                return {
                  ...item,
                  selected: false,
                };
              });

              return of$(
                checkPrice.failure(error),
                updateList(newProductsList),
              );
            default:
              return of$(checkPrice.failure(error));
          }
        }),
      );
    }),
    catchError$(() => {
      return of$(checkPrice.failure(SOMETHING_WENT_WRONG_TEXT));
    }),
  );
};

export const displayErrorMessageWhenCheckPriceFailure: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(checkPrice.failure)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return of$(add({ text: action.payload }));
    }),
  );
};

export const catchCheckPriceWhenReservationStateHasBeChanged: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setSlot, setSpace])),
    mergeMap$(() => {
      return of$(checkPrice.request(''), scrollTo(PLACE_ID));
    }),
  );
};
