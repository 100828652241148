import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IGetProductsPayload,
  IGetProductsPayloadSuccess,
} from '@Model/products/types';

class ProductsApi {
  private static getProductsUrl(productPayload: IGetProductsPayload): string {
    return `${config.api.baseUrl}spaces/${productPayload.spaceId}/products?dateTime=${productPayload.dateTime}`;
  }

  private cancelTokenProducts?: CancelTokenSource;

  public getProducts<T extends IGetProductsPayloadSuccess>(
    productPayload: IGetProductsPayload,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      axios
        .get(ProductsApi.getProductsUrl(productPayload), {
          cancelToken: this.cancelTokenProducts.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getCarnets<
    T extends IGetProductsPayloadSuccess
  >(): // productPayload: IGetProductsPayload,
  Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      // resolve

      resolve({
        // TODO: get from api
        count: 1,
        items: [
          {
            autocomplete: false,
            description:
              'Do wykorzystania od poniedziałku do piątku w każdym z Hangarów 646',
            subDescriptions: [
              'Możliwość zrobienia jednej rezerwacji dziennie',
              'Para skarpetek antypoślizgowych i hangarowa opaska gratis',
              'Ważny przez 30 dni od zakupu',
            ],
            id: 33,
            name: 'Karnet poniedziałek-piątek',
            price: 149,
            vat: 23,
          },
          {
            id: 144,
            name: 'Karnet tygodniowy',
            description:
              'Do wykorzystania przez cały tydzień w każdym z Hangarów 646',
            subDescriptions: [
              'Możliwość zrobienia jednej rezerwacji dziennie',
              'Para skarpetek antypoślizgowych i hangarowa opaska gratis',
              'Ważny przez 30 dni od zakupu',
            ],
            price: 299,
            autocomplete: false,
            vat: 23,
          },
        ],
      } as any);

      // axios
      //   .get(ProductsApi.getProductsUrl(productPayload), {
      //     cancelToken: this.cancelTokenProducts.token,
      //   })
      //   .then(getData)
      //   .then((response: T) => {
      //     resolve(response);
      //   })
      //   .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeProducts(response: IGetProductsPayloadSuccess): any {
    return {
      count: response.count,
      items: response.items,
    };
  }

  public cancelProducts() {
    if (this.cancelTokenProducts) {
      this.cancelTokenProducts.cancel();
      this.cancelTokenProducts = undefined;
    }
  }
}

export default new ProductsApi();
