import { IGoogleAnalytics } from '../analytics.types';

export const googleAnalytics = (googleGtmKet: IGoogleAnalytics) => {
  import('@Misc/helpers/loadDOMScript').then((loadDOMScript) => {
    loadDOMScript
      .default('/static/analytics/gtm.js', { 'data-gtm-key': googleGtmKet })
      // tslint:disable-next-line: no-empty
      .then(() => {})
      .catch((error: any) => {
        console.error('Error', error);
      });
  });
};

export default googleAnalytics;
