import {
  createMatchSelector,
  LOCATION_CHANGE,
  push,
  RouterRootState,
} from 'connected-react-router';
import format from 'date-fns/format';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import routes from '@/routes/routes';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import { getSelectedDay, getValues } from '@Model/booking/selector';
import { add } from '@Model/errors/actions';
import { getHappening, happeningMounted } from '@Model/happening/actions';
import { get } from '@Model/happening/selectors';
import { IHappeningRouterParams } from '@Model/happening/types';
import { runFbqAction } from '@Model/iframe/actions';
import { getProducts } from '@Model/products/selectors/index';
import {
  getTerms,
  reservationMounted,
  setTerms,
} from '@Model/reservation/actions';
import getAdditionalTerms from '@Model/reservation/selectors/getAdditionalTerms';
import {
  IAvailabilitiesReducer,
  IAvailabilitiesResponseDay,
} from '@Services/$availabilities-api/types';
import _Store from '@Store';
import {
  getAvailabilities,
  setDay,
  setDurationTimeAfterMidnight,
  setSpace,
} from './../actions';

const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

export const whenHappeningMountedSetDay: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(happeningMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const selectedDay = getSelectedDay(state);

      if (selectedDay) {
        return EMPTY$;
      }

      return [setDay(new Date())];
    }),
  );
};

export const whenDayIsSetGetAvailabilities: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([setDay, getHappening.success])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const happening = get(state);

      if (!happening) {
        return EMPTY$;
      }

      const meta = getMetadataForLanguage(happening.metadata);

      return [
        getAvailabilities.request({
          date: format(getSelectedDay(state) || new Date(), 'yyyy-MM-dd'),
          slug: meta.slug,
        }),
      ];
    }),
  );
};

export const whenGetHappeningCheckTimeIsBetweenMidnightAndTime: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const happening = get(state);

      if (!happening || !happening.startShowingSlotsAt) {
        return EMPTY$;
      }

      const { startShowingSlotsAt } = happening;

      const slotDateTime = moment();
      const time = moment(startShowingSlotsAt, 'HH:mm:ss');

      slotDateTime.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second'),
      });

      const midnightTime = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const now = moment();

      const isBetweenMidnightAndTime: boolean = now.isBetween(
        midnightTime,
        slotDateTime,
      );

      if (isBetweenMidnightAndTime) {
        return [
          setDay(
            moment()
              .subtract(1, 'days')
              .toDate(),
          ),
          setDurationTimeAfterMidnight(),
        ];
      }

      return EMPTY$;
    }),
  );
};

export const whenGetAvailabilitiesRequested: _Store.IEpic = (
  action$,
  state$,
  { availabilitiesApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailabilities.request)),
    mergeMap$((action) => {
      return from$(
        availabilitiesApi.getAvailabilities(
          action.payload.slug,
          action.payload.date,
        ),
      ).pipe(
        map$((data) => {
          const normalizedData = availabilitiesApi.normalizeAvailabilities(
            data,
            action.payload.date,
          );

          const otherDays: IAvailabilitiesResponseDay = {};

          Object.keys(data.items).map((key) => {
            if (key !== action.payload.date && data.items[key]) {
              Object.keys(data.items[key]).forEach((key2) => {
                if (data.items[key][key2]) {
                  otherDays[key2] = data.items[key][key2];
                }
              });
            }
          });

          const available: IAvailabilitiesReducer = {
            currentDay: normalizedData,
            otherDays,
          };

          return getAvailabilities.success(available);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => availabilitiesApi.cancelAvailabilities()),
          ),
        ),
        catchError$((error: Error) => of$(getAvailabilities.failure(error))),
      );
    }),
  );
};

export const whenReservationMounted: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider, linksProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(reservationMounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      iframeProvider.runScrollTopMethod();

      const matchSelector = createMatchSelector<
        RouterRootState,
        IHappeningRouterParams
      >(routes.reservation);

      const happeing = get(state);
      const { selectedCarnet } = getProducts(state);

      const match = matchSelector(state);

      const isPageWithState =
        match && match.params && !(happeing || selectedCarnet);

      if (isPageWithState && match) {
        const { slug } = match.params;
        const url = linksProvider.buildHappeningLink(slug);

        return [push(url)];
      }

      return EMPTY$;
    }),
  );
};

export const whenReservationMountedRunFbqAction: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(reservationMounted)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return [
        runFbqAction({
          action: 'track',
          payload: 'AddToCard',
        }),
      ];
    }),
  );
};

export const whenReservationMountedGetRegulations: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(setSpace)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const happening = get(state);
      const values = getValues(state);

      if (happening) {
        const { spaces, metadata } = happening;
        const selectedSpace = spaces.find(
          (_space) => _space.id === values.space,
        );

        if (
          metadata &&
          metadata.length &&
          selectedSpace &&
          selectedSpace.metadata &&
          selectedSpace.metadata.length
        ) {
          const eventSlug = metadata[0].slug;
          const runDateSlug = selectedSpace.metadata[0].slug;

          return from$(bookingApi.getTerms(eventSlug, runDateSlug)).pipe(
            map$((data) => {
              return getTerms.success(data);
            }),
            catchError$((error: Error) => {
              return [getTerms.failure(error), add({ text: error.message })];
            }),
          );
        }
      }

      return of$(getTerms.failure(new Error(SOMETHING_WENT_WRONG_TEXT)));
    }),
  );
};

export const catchSetTermsWhenAction: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setTerms)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const terms = getAdditionalTerms(state);
      const selected = terms.map((term) => {
        if (term.id === Number(action.payload)) {
          return {
            ...term,
            selected: !term.selected || false,
          };
        }
        return term;
      });

      return of$(getTerms.success(selected));
    }),
  );
};
