import React from 'react';

import SingularProduct from '../SingularProduct';
import { IProductsProps } from './Products.types';

const Products = ({ haveUpSell, products }: IProductsProps) => {
  return (
    <>
      {(haveUpSell || (products && products.length)) &&
        products.map((product) => (
          <SingularProduct product={product} key={product.id} />
        ))}
    </>
  );
};

export default Products;
