import React from 'react';

import SliderComponent from '@Compo/forms/Slider';
import Config from '@Config';
import { ISliderProps } from './Slider.types';

import styles from './Slider.module.scss';

const { isHangar } = Config.theme;

const GAME_TIME_TEXT = isHangar ? 'Czas wejścia:' : 'Czas gry:';
const MIN_TEXT = 'min';

const Slider = ({
  activityDuration,
  maxPeopleCount,
  peopleCount,
  setPeopleCount,
}: ISliderProps) => (
  <div className={styles.slider}>
    <SliderComponent
      maxValue={maxPeopleCount}
      onChange={setPeopleCount}
      step={1}
      value={peopleCount}
    />

    <section className={styles.info}>
      <div className={styles.infoRow}>
        <span>{GAME_TIME_TEXT}</span>{' '}
        {activityDuration && (
          <span className={styles.value}>
            {activityDuration} {MIN_TEXT}
          </span>
        )}
      </div>
    </section>
  </div>
);

export default Slider;
