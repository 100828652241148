import { redirectToTryAgain } from '@Model/iframe/actions';
import { get as getIframeData } from '@Model/iframe/selectors';
import {
  getTransactionBookingIdentifier,
  getTransactionDateTime,
  getTransactionDetails,
} from '@Model/reservation/selectors';
import { MODULE_TRANSACTION_DETAILS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import {
  captureAnalyticsSummary,
  summaryMounted,
} from '@Model/summaries/actions';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Summary from './Summary.component';
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => {
  const transactionDetails = getTransactionDetails(state);

  if (transactionDetails && transactionDetails.transactionProducts) {
    return {
      bookingIdentifier: getTransactionBookingIdentifier(state),
      isLoading: getLoading(MODULE_TRANSACTION_DETAILS)(state),
      selectedDateTime: getTransactionDateTime(state),
      transactionProducts: transactionDetails.transactionProducts,
    };
  }

  return {
    bookingIdentifier: getTransactionBookingIdentifier(state),
    isLoading: getLoading(MODULE_TRANSACTION_DETAILS)(state),
    selectedDateTime: getTransactionDateTime(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISummaryFromDispatch => ({
  captureAnalyticsSummary: () => dispatch(captureAnalyticsSummary()),
  mounted: () => dispatch(summaryMounted()),
  redirectToTryAgain: () => dispatch(redirectToTryAgain()),
});

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);
