import React, { useEffect } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import Calendar from '@Compo/Happening/components/Calendar';
import OverbookingTimeSlots from '@Compo/Happening/components/OverbookingTimeSlots';
import Slider from '@Compo/Happening/components/Slider';
import Spaces from '@Compo/Happening/components/Spaces';
import SpaceCheckBox from '@Compo/Happening/components/SpacesCheckBox';
import TimeSlots from '@Compo/Happening/components/TimeSlots';
import { IHappeningProps } from '@Compo/Happening/Happening.types';
import Loading from '@Compo/layout/Loading';
import Config from '@Config';
import { priceFormatter } from '@Misc/helpers';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import _L from '@Misc/helpers/local';
import { IHappeningsItemMetadata } from '@Model/happenings/types';
import LOCALS from '@Vars/constants';
import HappeningCheckBox from '../Happenings/components/HappeningCheckBox';

import styles from './Happening.module.scss';

const BOOK_TEXT = 'Rezerwuj';
const PRICE_TEXT = 'Cena:';
const SELECT_DATE_AND_TIME_TEXT = 'Wybierz datę i godzinę';

const SELECT_PLAYERS_COUNT_TEXT = _L(LOCALS.SELECT_PLAYERS_TEXT);
const SELECT_PLACE_TEXT = _L(LOCALS.SELECT_PLACE_TEXT);

const Happening = ({
  happening,
  isBookingAvailable,
  isLoading,
  isLoadingAvailabilities,
  mounted,
  price,
  recommendedNumberOfPlayers,
  resetSpaceAndSlot,
  selectedSpace,
  getHappenings,
}: IHappeningProps) => {
  useEffect(() => {
    mounted();
    getHappenings();
  }, []);

  useEffect(() => {
    if (price === null) {
      resetSpaceAndSlot();
    }
  }, [price]);

  if (isLoading) {
    return <Loading />;
  }

  if (!happening) {
    return null;
  }

  const metadata = getMetadataForLanguage<IHappeningsItemMetadata>(
    happening.metadata,
  );

  const showPlayersCount = happening.calculatePricePerPerson;
  const { isHangar } = Config.theme;

  const INTRO_RESERVATION_TEXT = isHangar ? '' : metadata.title;

  return (
    <>
      <section className={styles.header}>
        <h1 className={styles.title}>{INTRO_RESERVATION_TEXT}</h1>

        {/* TODO: keep it */}
        {/* {!showPlayersCount && (
          <span className={styles.recommendation}>
            <img className={styles.userIcon} src="/static/user.svg" alt="" />
            {RECOMMENDED_PLAYERS_COUNT} {recommendedNumberOfPlayers}
          </span>
        )} */}
      </section>

      {isHangar && <HappeningCheckBox />}

      <section className={styles.content}>
        {showPlayersCount && (
          <div className={styles.box}>
            <h4 id="playerCount" className={styles.boxTitle}>
              {SELECT_PLAYERS_COUNT_TEXT}
            </h4>
            <Slider />
          </div>
        )}
        <div className={styles.box}>
          <h4 className={styles.boxTitle}>{SELECT_DATE_AND_TIME_TEXT}</h4>
          <div className={styles.flexContainer}>
            <Calendar />

            {isLoadingAvailabilities && <Loading />}
            {!showPlayersCount && !isLoadingAvailabilities && <TimeSlots />}
            {showPlayersCount && !isLoadingAvailabilities && (
              <OverbookingTimeSlots />
            )}
          </div>
        </div>

        <div className={styles.box}>
          <h4 id="place" className={styles.boxTitle}>
            {SELECT_PLACE_TEXT}
          </h4>
          {!isHangar ? <Spaces /> : <SpaceCheckBox />}
        </div>

        <div className={cn(styles.box, styles.summary)}>
          <div className={styles.price}>
            <span className={styles.text}>{PRICE_TEXT}</span>
            <span className={styles.value}>{priceFormatter(price)} PLN</span>
          </div>
          <Link
            className={cn(
              styles.reserveButton,
              (!isBookingAvailable || !selectedSpace) && styles.disabled,
            )}
            to={`/rezerwacja/${metadata.slug}`}
          >
            {BOOK_TEXT}
          </Link>
        </div>
      </section>
    </>
  );
};

export default Happening;
