import React, { useContext } from 'react';

import cn from 'classnames';

import { priceFormatter } from '@Misc/helpers';
import capitalise from '@Misc/helpers/strings/capitalise';
import format from 'date-fns/format';
import pl from 'date-fns/locale/pl';
import { clearContext } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Basket from './Components/Basket/Basket.component';
import styles from './SummaryBox.module.scss';
import { ISummaryBoxProps } from './SummaryBox.types';

const ONE_PERSON_TEXT = 'osoba';
const TWO_TO_FOUR_PEOPLE_TEXT = 'osoby';
const MORE_THAN_FOUR_PEOPLE_TEXT = 'osób';
const YOUR_BOOKING_TEXT = 'Twoja rezerwacja';
const CARNET_BOOKING_TEXT = 'Podsumowanie zakupu';
const START_DATE_TEXT = 'Ważny od ';

const SummaryBox = ({
  happeningTitle,
  isOverbookingAllowed,
  price,
  selectedDay,
  selectedPeopleCount,
  selectedTimeRange,
  carnet,
}: ISummaryBoxProps) => {
  let title = happeningTitle;
  const state = useContext(clearContext);

  const calculatePrice = (): number => {
    if (state) {
      return state.getSummedPrice(state.basketItems) + price;
    }
    return price;
  };

  if (isOverbookingAllowed) {
    if (selectedPeopleCount === 1) {
      title += ` (${selectedPeopleCount} ${ONE_PERSON_TEXT})`;
    } else if (selectedPeopleCount > 1 && selectedPeopleCount < 5) {
      title += ` (${selectedPeopleCount} ${TWO_TO_FOUR_PEOPLE_TEXT})`;
    } else {
      title += ` (${selectedPeopleCount} ${MORE_THAN_FOUR_PEOPLE_TEXT})`;
    }
  }

  const selectedDayString = capitalise(
    format(selectedDay, 'EEEE dd.MM', { locale: pl }),
  );

  const TITLE = carnet ? CARNET_BOOKING_TEXT : YOUR_BOOKING_TEXT;

  return (
    <div className={styles.summaryBox}>
      <section className={cn(!carnet && styles.topSection)}>
        <h3 className={styles.title}>{TITLE}</h3>
        {!carnet && <span className={styles.day}>{selectedDayString}</span>}
      </section>
      <section className={styles.section}>
        <div className={styles.row}>
          <span className={cn(carnet && styles.strong)}>{title}</span>
          {!carnet && <span>{priceFormatter(price)} PLN</span>}
        </div>
        <span className={styles.text}>
          {carnet && START_DATE_TEXT}
          <span className={cn(carnet && styles.strong)}>
            {selectedTimeRange}
          </span>
        </span>

        <Basket />
      </section>

      <section className={styles.section}>
        <div className={styles.summaryRow}>
          <span>Do zapłaty</span>
          <span className={styles.summaryPrice}>
            {priceFormatter(calculatePrice())} PLN
          </span>
        </div>
      </section>
    </div>
  );
};

export default SummaryBox;
