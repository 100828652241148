import * as React from 'react';

import {
  GOING_BASKET_ITEMS_NAME,
  GOING_BASKET_USER_DATA_NAME,
} from './GoingBasketProvider.constants';
import { IGoingBasketItem } from './GoingBasketProvider.types';

export interface IGoingBag<T, T2> {
  removeFromBag: (id: number) => void;
  addToBag: (reservation: T) => void;
  readLocalStorage: () => void;
  handleShowBacked: () => void;
  clearBasket: () => void;
  handleUserData: (userData: T2) => void;
  getSummedPrice: (basketItems: T[]) => number;
  basketItems: T[];
  userData: T2 | null;
  showBasket: boolean;
}

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const GoingBasketProvider = <T extends IGoingBasketItem, T2>() => {
  const context = React.createContext<IGoingBag<T, T2> | null>(null);

  const Store = ({ children }: { children: React.ReactNode }) => {
    const removeFromBag = (indx: number) => {
      setState((oldState) => {
        updateLocalStorage(
          oldState.basketItems.filter((product) => product.id !== indx),
        );
        return {
          ...oldState,
          basketItems: oldState.basketItems.filter(
            (product) => product.id !== indx,
          ),
        };
      });
    };

    const addToBag = (item: T) => {
      setState((oldState) => {
        updateLocalStorage([...oldState.basketItems, item]);
        return {
          ...oldState,
          basketItems: [...oldState.basketItems, item],
        };
      });
    };

    const handleShowBacked = () => {
      setState((oldState) => {
        return {
          ...oldState,
          showBasket: !oldState.showBasket,
        };
      });
    };

    const handleUserData = (userData: T2) => {
      setState((oldState) => {
        updateLocalStorage(oldState.basketItems, userData);

        return {
          ...oldState,
          userData,
        };
      });
    };

    const clearBasket = () => {
      setState((oldState) => {
        updateLocalStorage([], {} as T2);

        return {
          ...oldState,
          basketItems: [],
          userData: null,
        };
      });
    };

    const getSummedPrice = (basketItems: T[]): number => {
      if (basketItems && basketItems.length) {
        return basketItems.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.price;
        }, 0);
      }
      return 0;
    };

    const readLocalStorage = () => {
      if (
        localStorage &&
        IsJsonString(localStorage.getItem(GOING_BASKET_ITEMS_NAME) || '')
      ) {
        const basketItems = JSON.parse(
          localStorage.getItem(GOING_BASKET_ITEMS_NAME) || '',
        );
        const userData = JSON.parse(
          localStorage.getItem(GOING_BASKET_USER_DATA_NAME) || '',
        );

        setState((oldState) => {
          return {
            ...oldState,
            basketItems,
            userData,
          };
        });
      }
    };

    const initializeData: IGoingBag<T, T2> = {
      addToBag,
      basketItems: [],
      clearBasket,
      getSummedPrice,
      handleShowBacked,
      handleUserData,
      readLocalStorage,
      removeFromBag,
      showBasket: false,
      userData: null,
    };

    /**
     *  Main going state
     */
    const [basketState, setState] = React.useState(initializeData);

    /**
     * Save with local storage when state has been changed
     */
    const updateLocalStorage = (items: T[], userData?: T2 | null) => {
      if (localStorage) {
        localStorage.setItem(GOING_BASKET_ITEMS_NAME, JSON.stringify(items));

        if (userData) {
          localStorage.setItem(
            GOING_BASKET_USER_DATA_NAME,
            JSON.stringify(userData),
          );
        }
      }
    };

    React.useEffect(() => {
      readLocalStorage();
    }, []);
    return <context.Provider value={basketState}>{children}</context.Provider>;
  };

  return {
    ...context,
    Store,
    clearContext: context,
  };
};

export default GoingBasketProvider;
