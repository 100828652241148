import moment from 'moment';
import { createSelector } from 'reselect';
import getAvailabilities from './getAvailabilities';
import getValues from './getValues';

const getSelectedDayIncludeTimeSlot = createSelector(
  [getValues, getAvailabilities],
  (values, availabilities) => {
    if (
      values.day &&
      values.slot &&
      availabilities.otherDays &&
      availabilities.otherDays[values.slot]
    ) {
      return moment(values.day)
        .add('days', 1)
        .toDate();
    }
    return values.day;
  },
);

export default getSelectedDayIncludeTimeSlot;
