import { createMatchSelector, LOCATION_CHANGE } from 'connected-react-router';
import format from 'date-fns/format';
import normalizeUrl from 'normalize-url';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import routes from '@/routes/routes';
import oblateParentUrl from '@Misc/helpers/api/makeParentUrl';
import makeUrlForPaymentFromRouteString from '@Misc/helpers/api/makeUrlForPaymentFromRouteString';
import {
  getConfigurationForSelectedSpace,
  getSelectedDayIncludeTimeSlot,
  getValues,
} from '@Model/booking/selector';
import { add } from '@Model/errors/actions';
import { get as getHappening } from '@Model/happening/selectors';
import { runFbqAction } from '@Model/iframe/actions';
import { get as getIframe } from '@Model/iframe/selectors';
import { getProducts } from '@Model/products/selectors/index';
import {
  catchCompanyData,
  getCompanyData,
  setCompanyData,
  setUserData,
} from '@Model/reservation/actions';
import {
  getAdditionalTerms,
  isUpSellEnabled,
} from '@Model/reservation/selectors';
import getUserData from '@Model/reservation/selectors/getUserData';
import {
  captureAnalyticsSummary,
  summaryMounted,
} from '@Model/summaries/actions';
import {
  IBookingPayUUrlTransactionResponse,
  IBookingTransactionBody,
  ICompanyInfoResponse,
  ITransactionDetailsResponse,
} from '@Services/$booking-api/types';
import { IPriceCheckBody, ISelectedProduct } from '@Services/$price-api/types';
import _Store from '@Store';
import { ITerm } from './../../reservation/types/index';
import {
  captureTransactionsDetailsRequest,
  captureTransactionsEmpikDetailsRequest,
  sendTransaction,
} from './../actions';
import { IBookingUserData, IMatch } from './../types';

const HAPPENING_DOESNT_EXIST_TEXT = 'Błędne wydarzenie';
const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';

export const captureTransactionDetailsWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf([summaryMounted])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summary);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return of$(captureTransactionsDetailsRequest.request(id));
      }

      return EMPTY;
    }),
  );
};

export const runFbqActionWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(captureAnalyticsSummary)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summary);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        return of$(
          runFbqAction({
            action: 'track',
            payload: 'Purchase',
          }),
        );
      }

      return EMPTY;
    }),
  );
};

export const captureTransactionDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsDetailsRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(bookingApi.getTransactionDetails(action.payload)).pipe(
        mergeMap$((response: ITransactionDetailsResponse) => {
          return of$(captureTransactionsDetailsRequest.success(response));
        }),
        catchError$(() => {
          return of$(
            captureTransactionsEmpikDetailsRequest.request(action.payload),
          );
        }),
        catchError$(() => {
          return EMPTY;
        }),
      );
    }),
  );
};

export const captureTransactionEmpikDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsEmpikDetailsRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(bookingApi.getTransactionEmpikDetails(action.payload)).pipe(
        mergeMap$((response: ITransactionDetailsResponse) => {
          return of$(captureTransactionsDetailsRequest.success(response));
        }),
        catchError$(() => {
          return of$(
            captureTransactionsDetailsRequest.failure(
              new Error(HAPPENING_DOESNT_EXIST_TEXT),
            ),
          );
        }),
      );
    }),
    catchError$(() => {
      return EMPTY;
    }),
  );
};

export const postTransactionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(setUserData)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      let { parentUrl } = getIframe(state);
      const day = getSelectedDayIncludeTimeSlot(state);
      parentUrl = oblateParentUrl(parentUrl);

      const happening = getHappening(state);
      const userData = getUserData(state) as IBookingUserData;
      const configuration = getConfigurationForSelectedSpace(state);
      const upSellSelected = isUpSellEnabled(state);
      const values = getValues(state);
      const { items: products, selectedCarnet } = getProducts(state);
      const additionalTerms = getAdditionalTerms(state);

      const prepareFactureData = () => {
        if (
          userData.facture &&
          userData.nip &&
          userData.companyName &&
          userData.street &&
          userData.houseNumber &&
          userData.zipCode &&
          userData.city
        ) {
          return {
            invoice: {
              address: `${userData.street} ${userData.houseNumber}`,
              city: userData.city,
              email: userData.email,
              name: userData.companyName,
              nip: userData.nip,
              post: userData.zipCode,
            },
          };
        } else {
          return {};
        }
      };

      const selectedProducts = (): ISelectedProduct[] => {
        if (selectedCarnet) {
          return [
            {
              id: selectedCarnet.id,
              quantity: 1,
            },
          ];
        } else if (products && products.length) {
          return products
            .filter((item) => item.selected)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || 1,
            }));
        }
        return [];
      };

      if (!(values && (happening || selectedCarnet))) {
        return EMPTY;
      }

      const selectedSpace =
        happening &&
        happening.spaces.find((_space) => _space.id === values.space);

      const paymentFailLink = makeUrlForPaymentFromRouteString(
        normalizeUrl(parentUrl),
      );

      const paymentSuccessLink = makeUrlForPaymentFromRouteString(
        normalizeUrl(parentUrl),
      );

      const dateTime =
        values && values.day
          ? `${format(day || values.day, 'yyyy-MM-dd')}T${values.slot}+00:00`
          : null;

      const getNumberOfPeople = (): number => {
        if (happening && selectedSpace) {
          if (happening.calculatePricePerPerson && values.numberOfPlayers) {
            return values.numberOfPlayers;
          }

          return selectedSpace.maxNumberOfPeople;
        }

        return 0;
      };

      const getAcceptedTerms = (): number[] => {
        return additionalTerms
          .filter((term: ITerm) => term.selected)
          .map((term) => Number(term.id));
      };

      const getPassData = () => {
        if (userData.pass && userData.passCode) {
          return {
            passCode: userData.passCode,
          };
        }
        return {};
      };

      const getHappeningProps = () => {
        if (happening && selectedSpace) {
          return {
            ...getPassData(),
            dateTime,
            duration: selectedSpace.timeSlot,
            happeningId: happening.id,
            spaceId: selectedSpace.id,
          };
        }
        return {};
      };

      const body: IBookingTransactionBody = {
        ...getHappeningProps(),
        agent: 'zagrywki-web',

        linkFail: paymentFailLink,
        linkOk: paymentSuccessLink,
        numberOfPeople: getNumberOfPeople(),
        paymentOperator: 3,
        products: selectedProducts(),
        salesChannelId: 12,
        user: {
          acceptedTerms: getAcceptedTerms(),
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phoneNumber || '',
          terms: true,
        },
        ...prepareFactureData(),
      };

      if (
        (userData.discountCode || upSellSelected) &&
        dateTime &&
        configuration
      ) {
        const price = configuration.prices[0].value;

        const priceReduction: IPriceCheckBody = {
          dateTime,
          numberOfPeople: getNumberOfPeople(),
          price,
          products: selectedProducts(),
          spaceId: (selectedSpace && selectedSpace.id) || null,
        };

        if (userData.discountCode) {
          priceReduction.discount = {
            code: userData.discountCode,
          };
        }

        if (upSellSelected) {
          priceReduction.upsell = {
            configurationId: configuration.id,
          };
        }

        body.priceReduction = priceReduction;
      }

      return from$(bookingApi.postTransaction(body)).pipe(
        map$((response: IBookingPayUUrlTransactionResponse) => {
          const {
            payment: { formUrl },
            errors,
          } = response;

          if (errors && errors.length && errors[0] && errors[0].message) {
            const { message } = errors[0];
            const path = errors[0].path || '';

            return sendTransaction.failure(`${path} ${message}`);
          }

          if (formUrl) {
            return sendTransaction.success(formUrl);
          }

          return sendTransaction.failure(SOMETHING_WENT_WRONG_TEXT);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => bookingApi.cancelPostTransaction()),
          ),
        ),
        catchError$((error: string) => of$(sendTransaction.failure(error))),
      );
    }),
  );
};

export const showErrorMessageWhenBookingFailed: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.failure)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(add({ text: action.payload }));
    }),
  );
};

export const redirectToPayIWhenTransactionPosted: _Store.IEpic = (
  action$,
  state$,
  { iframeProvider },
) => {
  return action$.pipe(
    filter$(isActionOf(sendTransaction.success)),
    mergeMap$((action) => {
      iframeProvider.runRedirectParentMethod(action.payload);
      iframeProvider.runFbqMethod({
        action: 'track',
        payload: 'InitiateCheckout',
      });
      iframeProvider.runFbqMethod({
        action: 'track',
        payload: 'AddPaymentInfo',
      });

      return EMPTY;
    }),
  );
};

export const getCompanyDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { bookingApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getCompanyData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      if (typeof action.payload === 'string') {
        return from$(bookingApi.getCompanyData(action.payload)).pipe(
          mergeMap$((response: ICompanyInfoResponse) => {
            const {
              apartmentNumber,
              city,
              propertyNumber,
              name: companyName,
              postCode: zipCode,
              street,
              taxNumber: nip,
            } = response;
            const getPropertyNumber = (): string => {
              if (apartmentNumber) {
                return `${propertyNumber}/${apartmentNumber}`;
              }
              return propertyNumber;
            };

            const data = {
              city,
              companyName,
              facture: true,
              houseNumber: '',
              nip,
              propertyNumber: getPropertyNumber(),
              street,
              zipCode,
            };

            return of$(setCompanyData(data), getCompanyData.success());
          }),
          catchError$(() => {
            return of$(getCompanyData.failure());
          }),
        );
      }
      return EMPTY;
    }),
    catchError$(() => {
      return EMPTY;
    }),
  );
};

export const catchCompanyDataWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchCompanyData)),
    mergeMap$((action) => {
      return of$(getCompanyData.request(action.payload));
    }),
  );
};
