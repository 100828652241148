import React from 'react';

import cn from 'classnames';

import config from '@Config';
import getShortTime from '@Misc/helpers/dateTime/getShortTime';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import { ITimeSlotsProps } from './TimeSlots.types';

import styles from './TimeSlots.module.scss';

const exclamationUrl = config.theme.isHangar
  ? '/static/exclamation_black.svg'
  : '/static/exclamation.svg';

const COME_ON_TIME_TEXT = _L(LOCALS.REMEMBERS_TEXT);

const TimeSlots = ({ setSlot, timeSlots }: ITimeSlotsProps) => (
  <div className={styles.wrapper}>
    <ul className={styles.timeSlots}>
      {timeSlots.map((slot) => {
        if (!slot.isDisabled) {
          return (
            <li key={slot.start}>
              <button
                className={cn(
                  styles.button,
                  slot.isSelected && styles.selected,
                )}
                disabled={slot.isDisabled}
                /* tslint:disable-next-line:jsx-no-lambda */
                onClick={() => setSlot(slot.start)}
              >
                {getShortTime(slot.start)}
              </button>
            </li>
          );
        } else {
          return null;
        }
      })}
    </ul>
    {timeSlots && timeSlots.length ? (
      <div className={styles.info}>
        <img className={styles.infoIcon} src={exclamationUrl} alt="" />
        {COME_ON_TIME_TEXT}
      </div>
    ) : null}
  </div>
);

export default TimeSlots;
