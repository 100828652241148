import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { reservationMounted, setUserData } from '@Model/reservation/actions';
import get from '@Model/reservation/selectors/get';
import { IReservationUser } from '@Model/reservation/types';
import { MODULE_BOOKING } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import BookForm from './BookForm.component';
import { IBookFormFromDispatch, IBookFormFromState } from './BookForm.types';

const mapStateToProps = (state: _Store.IState): IBookFormFromState => {
  const { companyData, formData } = get(state);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    facture,
    nip,
    discountCode,
    pass,
    passCode,
  } = formData;
  const {
    companyName,
    street,
    houseNumber,
    zipCode,
    city,
    propertyNumber,
  } = companyData;
  return {
    initialValues: {
      discountCode,
      email,
      facture,
      firstName,
      lastName,
      nip,
      pass,
      passCode,
      phoneNumber,

      city,
      companyName,
      houseNumber: propertyNumber || houseNumber || '',
      street,
      zipCode,
    },
    isLoading: getLoading(MODULE_BOOKING)(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IBookFormFromDispatch => ({
  mounted: () => dispatch(reservationMounted()),
  saveData: (data: IReservationUser) => dispatch(setUserData(data)),
});

export default connect<
  IBookFormFromState,
  IBookFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(BookForm);
