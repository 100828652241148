import { captureTransactionsDetailsRequest } from '@Model/transaction/actions';
import _Store from '@Store';
import { getType } from 'typesafe-actions';
import { IReservationTransaction } from './../types';

const initialState: IReservationTransaction | null = null;

const transactionReducer = (
  state: IReservationTransaction | null = initialState,
  action: _Store.IAction,
): IReservationTransaction | null => {
  switch (action.type) {
    case getType(captureTransactionsDetailsRequest.success):
      // @ts-ignore
      return {
        ...action.payload,
        ...state,
        bookingIdentifier: action.payload.bookingIdentifier,
        dateTime: action.payload.start,
      };

    default:
      return state;
  }
};

export default transactionReducer;
