import { IBookFormValues } from '@Compo/BookForm/BookForm.types';
import { IZagrywkiBasket } from '@Model/basket/types';
import GoingBasketProvider from '../GoingBasketProvider/GoingBasketProvider';

export const BagContext = GoingBasketProvider<
  IZagrywkiBasket,
  IBookFormValues
>();

export const BagContextConsumer = BagContext.Consumer;
export const BagStore = BagContext.Store;
export const clearContext = BagContext.clearContext;
