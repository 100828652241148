import React from 'react';

import cn from 'classnames';

import Config from '@Config';
import { priceFormatter } from '@Misc/helpers';
import Products from './components/Products';
import { IUpSellProps } from './UpSell.types';

import styles from './UpSell.module.scss';

const ADD_TEXT = 'Dodaj';
const ADD_SMALL_TEXT = '+';
const REMOVE_TEXT = 'Usuń';
const REMOVE_SMALL_TEXT = '-';
const ENLARGE_TEXT = 'Wydłuż grę o';
const MINUTES_TEXT = 'minut';
const YOUR_PLAY_WILL_LAST_TILL_TEXT = 'Wasza zabawa będzie trwać do';

const UpSell = ({
  additionalDuration,
  checkUpSell,
  finishTime,
  isBigDesktop,
  isDesktop,
  isUpSellSelected,
  resetUpSell,
  upSellPrice,
  haveUpSell,
}: IUpSellProps) => {
  const showFullText = isBigDesktop || isDesktop;
  const { isHangar } = Config.theme;

  const addingValue = `+ ${priceFormatter(upSellPrice)} PLN`;
  const icon = isHangar
    ? '/static/another-hour-black.svg'
    : '/static/another-hour.svg';

  return (
    <div className={styles.upSell}>
      {haveUpSell && (
        <div className={styles.row}>
          <img className={styles.image} src={icon} alt="" />
          <div className={styles.info}>
            <span className={styles.infoTitle}>
              {ENLARGE_TEXT} {additionalDuration} {MINUTES_TEXT}
            </span>
            <span className={styles.infoDescription}>
              {YOUR_PLAY_WILL_LAST_TILL_TEXT} {finishTime}
            </span>
          </div>
          <div className={styles.actions}>
            <div className={styles.addingPrice}>{addingValue}</div>
            <div className={styles.buttons}>
              {!isUpSellSelected && (
                <button
                  onClick={checkUpSell}
                  className={styles.button}
                  type="button"
                >
                  {showFullText && ADD_TEXT}
                  {!showFullText && ADD_SMALL_TEXT}
                </button>
              )}
              {isUpSellSelected && (
                <button
                  onClick={resetUpSell}
                  className={cn(styles.button, styles.buttonSelected)}
                  type="button"
                >
                  {showFullText && REMOVE_TEXT}
                  {!showFullText && REMOVE_SMALL_TEXT}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <Products />
    </div>
  );
};

export default UpSell;
