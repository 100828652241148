import React, { useEffect } from 'react';

import Routes from '@/routes';
import Error from '@Compo/Error';
import Resizer from '@Compo/functional/Resizer';
import '../../misc/styles/global.module.scss';

import 'rc-slider/assets/index.css';
import { BagStore } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';

const App = () => {
  useEffect(() => {
    // mounted(limit);
  }, []);

  return (
    <>
      <BagStore>
        {/* <div id="embeddingContainer"></div> */}
        {/* Components which have side-effects e.g.: connected with redux */}
        <Resizer />

        <Error />

        {/* react-router v4 routing */}
        <Routes />
      </BagStore>
    </>
  );
};

export default App;
