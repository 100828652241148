import { removeError } from '@Model/errors/actions';
import { get } from '@Model/errors/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import ErrorComponent from './Error.component';
import { IErrorFromDispatch, IErrorFromState } from './Error.types';

const mapStateToProps = (state: _Store.IState): IErrorFromState => {
  return {
    error: get(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IErrorFromDispatch => {
  return {
    remove: () => {
      dispatch(removeError());
    },
  };
};

const Errors = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorComponent);

export default Errors;
