import { get as getHappening, getSpaces } from '@Model/happening/selectors';
import { createSelector } from 'reselect';
import getAvailabilities from './getAvailabilities';
import getValues from './getValues';

const calculateAvailableSpaces = createSelector(
  [getAvailabilities, getValues, getSpaces, getHappening],
  (availabilities, values, spaces, happening) => {
    const { slot, day } = values;

    if (!(slot && day && availabilities && spaces)) {
      return [];
    }

    const { currentDay, otherDays } = availabilities;

    let availability = currentDay[slot];

    if (otherDays && otherDays[slot]) {
      availability = otherDays[slot];
    }

    if (!availability) {
      return [];
    }

    const allowNextSlotWhenOverbooked =
      happening && happening.allowNextSlotWhenOverbooked
        ? happening.allowNextSlotWhenOverbooked
        : false;

    return spaces.map((_space) => {
      const slotBlock = availability.find(
        (_availability) => _availability.spaceId === _space.id,
      );

      let isDisable = true;

      if (slotBlock) {
        isDisable = !slotBlock.available;
      }

      if (
        values &&
        values.numberOfPlayers &&
        !isDisable &&
        !allowNextSlotWhenOverbooked
      ) {
        isDisable = _space.maxNumberOfPeople < values.numberOfPlayers;
      }

      return {
        ..._space,
        isDisable,
      };
    });
  },
);

export default calculateAvailableSpaces;
