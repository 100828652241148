import React, { useEffect } from 'react';

import Loading from '@Compo/layout/Loading';

import config from '@Config';
import _L from '@Misc/helpers/local';
import LOCALS from '@Vars/constants';
import HappeningBox from './components/HappeningBox';
import HappeningCheckBox from './components/HappeningCheckBox';
import { IHappeningsProps } from './Happenings.types';

import styles from './Happenings.module.scss';

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

const INTRO_RESERVATION_TEXT = _L(LOCALS.INTRO_RESERVATION_TEXT);

const Happenings = ({
  mounted,
  isLoading,
  unmount,
  url: urlPath,
}: IHappeningsProps) => {
  useEffect(() => {
    mounted();

    console.log('urlPath', urlPath.split('=')[1]);
    // console.log('urlPath', urlPath.split('='));

    const url =
      urlPath.split('=')[1] ||
      'https://eu-central-1.quicksight.aws.amazon.com/embed/6c4f4c42f8d34882975af11eb2eeabb4/dashboards/1fa00693-29e5-4b30-9b80-7168ace7bf14?code=AYABeOdK3cKwR4vdc_FVycFQya8AAAABAAdhd3Mta21zAE5hcm46YXdzOmttczpldS1jZW50cmFsLTE6NjI5OTIyMjM1NDQ1OmtleS83NzZjMTM3ZS1hMzg0LTQ5YWUtOWM5Zi05N2Y2MWEyMzliMTMAuAECAQB4tO8ZxahB-XPJWTDwDlTGysUDcuChdPd2I2oph5qVaBsB0MA4Xh7T7-7X1xoMWNwXlAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDPA1vUOUvj5z4TvNHgIBEIA7xw0MA5RSzXyLhetgCysJfdIv1fl4Gu2rih5hu_j6dNdpBzgPtDfKdH_he-etSwkUWW0Eie7R5d_yV2YCAAAAAAwAABAAAAAAAAAAAAAAAAAAdPWKXPtD2SgIfnIfEmuXQv____8AAAABAAAAAAAAAAAAAAABAAAA6NFy4rQleSxD-GyCqV-i0Tn7QGnL3FHSCNHCcdidy5TB6-fakD38ewkfIFlDTU8XCR7KJw-gsETHSsZx-qStAzV_jUusI8a9DUXddbpQS0BZpNwoCGTmkarX3tqNKeVUfwxI16CruEOoA7_6__mDpskwAS4kmnNzApEgksIYgHMRWnjpY3U7eTUa7KaLGIzjl7igN0HbMwoeCSP2PjeNr-UtZB3zjVW17hg3xjeHnY0X8Zn5vWSlz5aa8NsLHJY9TOwC42bAeT6BeNeBSl48AylcXwyFQigGtl1BeF0pl45Hjia40BTvLdFKBiCu15oZTY-HqQMBqG4J&identityprovider=quicksight&isauthcode=true';
    const containerDiv = document.getElementById('embeddingContainer');
    const options = {
      url,
      container: containerDiv,
      parameters: {
        country: 'Poland',
      },
      scrolling: 'no',
      height: '700px',
      width: '1000px',
      locale: 'en-US',
      footerPaddingEnabled: true,
      defaultEmbeddingVisualType: 'TABLE', // this option only applies to QuickSight console embedding and is not used for dashboard embedding
    };

    console.log('!!!! containerDiv', containerDiv);

    const dashboard = embedDashboard(options);
    dashboard.on('error', (err: any) => {
      console.log('errrrrr', err);
    });

    console.log('!!!!!!!!!');

    return () => {
      unmount();
    };
  }, []);

  const {
    theme: { isHangar },
  } = config;

  return (
    <>
      <div id="embeddingContainer"></div>
      {!isHangar && <h1 className={styles.title}>{INTRO_RESERVATION_TEXT}</h1>}

      {!isLoading && !isHangar && <HappeningBox />}

      {!isLoading && isHangar && <HappeningCheckBox />}

      {isLoading && <Loading />}
    </>
  );
};

export default Happenings;
