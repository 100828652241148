import { getType } from 'typesafe-actions';

import { setFormData } from '../actions';
import { IAction, IFormDataReduce } from '../types';

const initialState: IFormDataReduce = {
  discountCode: '',
  email: '',
  facture: false,
  firstName: '',
  lastName: '',
  pass: false,
  phoneNumber: '',
};

const companyDataFromReduce = (
  state: IFormDataReduce = initialState,
  action: IAction,
): IFormDataReduce => {
  switch (action.type) {
    case getType(setFormData):
      return action.payload;
    default:
      return state;
  }
};

export default companyDataFromReduce;
