import { ITransactionDetailsResponse } from '@Services/$booking-api/types';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _CAPTURE_TRANSACTION_DETAILS_FAILURE,
  _CAPTURE_TRANSACTION_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS,
  _SEND_TRANSACTION_FAILURE,
  _SEND_TRANSACTION_REQUEST,
  _SEND_TRANSACTION_SUCCESS,
  SET_BOOKING_TERMS,
  SET_BOOKING_USER_DATA,
} from './../constants/actions';
import { IBookingUserData } from './../types';

export const setBookingUserData = createStandardAction(SET_BOOKING_USER_DATA)<
  IBookingUserData
>();

export const setBookingTerms = createStandardAction(SET_BOOKING_TERMS)<
  boolean
>();

export const captureTransactionsDetailsRequest = createAsyncAction(
  _CAPTURE_TRANSACTION_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_DETAILS_FAILURE,
)<string, ITransactionDetailsResponse, Error>();

export const captureTransactionsEmpikDetailsRequest = createAsyncAction(
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS,
  _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE,
)<string, ITransactionDetailsResponse, Error>();

export const sendTransaction = createAsyncAction(
  _SEND_TRANSACTION_REQUEST,
  _SEND_TRANSACTION_SUCCESS,
  _SEND_TRANSACTION_FAILURE,
)<undefined, string, string>();
