import React, { useEffect } from 'react';

import Loading from '@Compo/layout/Loading';
import cn from 'classnames';
import { ISummaryProps } from './Summary.types';

import styles from './Summary.module.scss';

const BOOKING_SUCCESSFUL_TEXT =
  'Dziękujemy za rezerwację. Oczekujemy na potwierdzenie przyjęcia pieniędzy. Niedługo dostaniesz od nas maila z potwierdzeniem rezerwacji.';
const BOOKING_CARNET_SUCCESSFUL_TEXT =
  'Dziękujemy za zakup karnetu. Oczekujemy na potwierdzenie przyjęcia pieniędzy. Niedługo dostaniesz od nas maila z potwierdzeniem karnetu.';
const PLEASE_COME_EARLIER_TEXT = 'Prosimy o przyjście 20 minut wcześniej!';
const HAPPENING_DOESNT_EXIST_TEXT = 'Błędne wydarzenie';
const CONTINUE_TEXT = 'Kontynuuj zakupy';
const TRY_AGAIN_TEXT = 'Spróbuj ponownie';

const Summary = ({
  bookingIdentifier,
  isLoading,
  mounted,
  redirectToTryAgain,
  selectedDateTime,
  transactionProducts,
  captureAnalyticsSummary,
}: ISummaryProps) => {
  useEffect(() => {
    mounted();

    if (bookingIdentifier && !transactionProducts) {
      captureAnalyticsSummary();
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!bookingIdentifier && !transactionProducts) {
    return (
      <section className={styles.header}>
        <h1 className={styles.title}>{HAPPENING_DOESNT_EXIST_TEXT} </h1>

        <div onClick={redirectToTryAgain}>
          <div className={styles.button}>{TRY_AGAIN_TEXT}</div>
        </div>
      </section>
    );
  }

  const isCarnet = !bookingIdentifier && transactionProducts;

  return (
    <>
      <section className={styles.header}>
        <h1 className={styles.title}>
          {isCarnet ? BOOKING_CARNET_SUCCESSFUL_TEXT : BOOKING_SUCCESSFUL_TEXT}{' '}
        </h1>
      </section>
      <section className={styles.info}>
        {bookingIdentifier && (
          <div className={styles.infoRow}>
            <span className={styles.infoName}>Numer rezerwacji: </span>
            <span className={styles.infoValue}>{bookingIdentifier}</span>
          </div>
        )}
        {selectedDateTime && (
          <div className={styles.infoRow}>
            <span className={styles.infoName}>Data i godzina rezerwacji: </span>
            <span className={cn(styles.infoValue, styles.date)}>
              {selectedDateTime}
            </span>
          </div>
        )}

        {!isCarnet && (
          <div className={styles.additionalInfo}>
            <img className={styles.timerIcon} src="/static/timer.svg" alt="" />
            {PLEASE_COME_EARLIER_TEXT}
          </div>
        )}

        <div onClick={redirectToTryAgain}>
          <div className={styles.button}>{CONTINUE_TEXT}</div>
        </div>
      </section>
    </>
  );
};

export default Summary;
