import { createStandardAction } from 'typesafe-actions';
import {
  CAPTURE_EVENT_FROM_IFRAME,
  MOUNTED,
  REDIRECT_TO_TRY_AGAIN,
  REFRESH_PAGE,
  RESIZE,
  RUN_FBQ_ACTION,
  SCROLL_TO,
  SCROLL_TO_TOP,
  SET_CURRENT_URL_IFRAME_PROVIDER,
  SET_ENTER_FROM_URL,
  SET_EXTERNAL_FORM_URL,
  SET_PARTNER_ID,
  SET_PASS_CODE,
  SET_ROOM,
  SET_ROOM_URL,
  START_MOUNTED,
} from '../constants/actions';
import { IEvent, IFbqAction } from '../types';

export const mounted = createStandardAction(MOUNTED)();

export const setRoom = createStandardAction(SET_ROOM)<string>();

export const captureIframeEvent = createStandardAction(
  CAPTURE_EVENT_FROM_IFRAME,
)<IEvent>();

export const startMounted = createStandardAction(START_MOUNTED)();

export const setParentUrl = createStandardAction(
  SET_CURRENT_URL_IFRAME_PROVIDER,
)<string>();

export const setPartnerId = createStandardAction(SET_PARTNER_ID)<
  string | null
>();

export const setExternalFormUrl = createStandardAction(SET_EXTERNAL_FORM_URL)<
  string | null
>();

export const setRoomUrl = createStandardAction(SET_ROOM_URL)<string>();

export const setEnterFromUrl = createStandardAction(SET_ENTER_FROM_URL)<
  string | null
>();
export const setPassCode = createStandardAction(SET_PASS_CODE)<string | null>();

export const redirectToTryAgain = createStandardAction(REDIRECT_TO_TRY_AGAIN)();

export const setIframeSizes = createStandardAction(RESIZE)<string>();

export const scrollIframeTo = createStandardAction(SCROLL_TO)<number>();

export const scrollIframeToTop = createStandardAction(SCROLL_TO_TOP)();

export const refreshPage = createStandardAction(REFRESH_PAGE)();

export const runFbqAction = createStandardAction(RUN_FBQ_ACTION)<IFbqAction>();
