import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import axios, { CancelTokenSource } from 'axios';
import { IPriceCheckBody, IPriceCheckResponse } from './types';

class PriceApi {
  private static getUrl() {
    return `${config.api.baseUrl}price`;
  }

  private cancelTokenPriceCHeck?: CancelTokenSource;

  public checkPrice(body: IPriceCheckBody): Promise<IPriceCheckResponse> {
    return new Promise<IPriceCheckResponse>((resolve, reject) => {
      this.cancelTokenPriceCHeck = axios.CancelToken.source();

      return axios
        .post(PriceApi.getUrl(), body, {
          cancelToken: this.cancelTokenPriceCHeck.token,
        })
        .then(getData)
        .then((data: IPriceCheckResponse) => {
          if (!data.value && data.message) {
            reject(data.message);
          } else if (
            data.errors &&
            data.errors.length &&
            data.errors[0] &&
            data.errors[0].message
          ) {
            const { message } = data.errors[0];
            const path = data.errors[0].path || '';

            reject(`${path} ${message}`);
          } else {
            resolve(data);
          }
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelCheckPrice() {
    if (this.cancelTokenPriceCHeck) {
      this.cancelTokenPriceCHeck.cancel();
      this.cancelTokenPriceCHeck = undefined;
    }
  }
}

export default new PriceApi();
