import { get } from '@Model/happening/selectors';
import { createSelector } from 'reselect';
import getAvailabilities from './getAvailabilities';
import getValues from './getValues';

const DEFAULT_PRICE = 0;

const calculatePrice = createSelector(
  [getAvailabilities, getValues, get],
  (availabilities, values, happening) => {
    const { slot, space, day, numberOfPlayers } = values;

    if (!(slot && space && day && availabilities && happening)) {
      return DEFAULT_PRICE;
    }

    const { currentDay, otherDays } = availabilities;

    let availability = currentDay[slot];

    if (otherDays && otherDays[slot]) {
      availability = otherDays[slot];
    }

    if (!availability) {
      return DEFAULT_PRICE;
    }

    const foundedSpace = availability.find(
      (_space) => _space.spaceId === space,
    );

    if (!foundedSpace) {
      return DEFAULT_PRICE;
    }

    const price = foundedSpace.price;

    let returnedPrice;

    if (!happening.calculatePricePerPerson) {
      returnedPrice = price;
    } else {
      returnedPrice = numberOfPlayers * price;
    }

    if (returnedPrice) {
      return returnedPrice;
    }

    return null;
  },
);

export default calculatePrice;
