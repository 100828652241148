import React from 'react';

import Checkbox from '@Compo/forms/Checkbox';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import {
  IHappeningSpace,
  IHappeningSpaceMetadata,
} from '@Model/happening/types';
import cn from 'classnames';
import styles from './Spaces.module.scss';
import { ISpacesProps } from './Spaces.types';

const Spaces = ({
  selectedSpace,
  spaces,
  selectSpace,
  isMobile,
}: ISpacesProps) => {
  const isSpaceSelected = (space: IHappeningSpace) =>
    (selectedSpace && selectedSpace === space.id) || false;

  const getSpaceMetadata = (space: IHappeningSpace) =>
    getMetadataForLanguage<IHappeningSpaceMetadata>(space.metadata);

  return (
    <ul className={styles.spaces}>
      {spaces.map((space) => {
        const isSelected = selectedSpace === space.id;

        return (
          <li
            className={cn(styles.space, space.isDisable && styles.disabled)}
            key={space.id}
          >
            <div
              className={cn(styles.linkBox, isSelected && styles.selected)}
              /* tslint:disable-next-line:jsx-no-lambda */
              onClick={() => selectSpace(space.id)}
              role="button"
            >
              <section
                className={styles.image}
                style={{
                  backgroundImage: `url(${space.url || ''})`,
                }}
              />
              <section className={styles.description}>
                <Checkbox
                  checked={isSpaceSelected(space)}
                  /* tslint:disable-next-line:jsx-no-lambda */
                  onChange={() => selectSpace(space.id)}
                  hideCheck={isMobile}
                >
                  {getSpaceMetadata(space).title}
                </Checkbox>
              </section>
              <section className={styles.name}>
                {getSpaceMetadata(space).description}
              </section>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Spaces;
