import React from 'react';

import cn from 'classnames';

import config from '@Config';
import { ILoadingProps } from './Loading.types';

import styles from './Loading.module.scss';

const url = config.theme.isHangar
  ? '/static/loader_black.svg'
  : '/static/loader.svg';

const Loading = ({ fixed = false }: ILoadingProps) => {
  return (
    <div className={cn(styles.loading, fixed && styles.fixed)}>
      <img className={styles.image} src={url} alt="Loading..." />
    </div>
  );
};

export default Loading;
