import { defaultMemoize } from 'reselect';
import { boolean, object, string } from 'yup';

import { IBookFormFieldsValidators } from '@Compo/BookForm/BookForm.types';

const EMAIL_ADDRESS_IS_REQUIRED_VALIDATOR = 'Adres e-mail jest wymagany';
const EMAIL_ADDRESS_IS_INCORRECT_VALIDATOR = 'Adres e-mail jest niepoprawny';
const PHONE_NUMBER_IS_INCORRECT_VALIDATOR = 'Numer telefonu jest niepoprawny';
const FIRST_NAME_IS_REQUIRED_VALIDATOR = 'Imię jest wymagane';
const LAST_NAME_IS_REQUIRED_VALIDATOR = 'Nazwisko jest wymagane';

const NIP_IS_REQUIRED_VALIDATOR = 'Nip jest wymagany';
const COMPANY_NAME_IS_REQUIRED_VALIDATOR = 'Nazwa firmy jest wymagana';
const STREET_IS_REQUIRED_VALIDATOR = 'Ulica jest wymagana';
const CHOSE_NUMBER_IS_REQUIRED_VALIDATOR = 'Numer jest wymagany';
const ZIP_CODE_REQUIRED_VALIDATOR = 'Kod pocztowy jest wymagany';
const CITY_IS_REQUIRED_VALIDATOR = 'Miasto jest wymagane';
const NIP_INCORRECT_TEXT = 'Nip nieprawidłowy';
const ZIP_CODE_IS_INCORRECT_VALIDATOR = 'Poprawny format to ##-###';

const makeValidationSchemaToMemoize = () => {
  const rePhoneNumber = /^\+*(\d[\s-]*){9,20}$/;
  const reZipCode = /^[0-9]{2}-[0-9]{3}$/;

  const isValidNip = (nip: string): boolean => {
    if (typeof nip !== 'string') {
      return false;
    }

    nip = nip.replace(/[\ \-]/gi, '');

    const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    // tslint:disable-next-line: radix
    const controlNumber = parseInt(nip.substring(9, 10));
    const weightCount = weight.length;
    for (let i = 0; i < weightCount; i++) {
      // tslint:disable-next-line: radix
      sum += parseInt(nip.substr(i, 1)) * weight[i];
    }

    return sum % 11 === controlNumber;
  };

  const factureGapsSchema = (error: string) => {
    return {
      is: true,
      otherwise: string(),
      then: string().required(error),
    };
  };

  const validators: IBookFormFieldsValidators = {
    email: string()
      .required(EMAIL_ADDRESS_IS_REQUIRED_VALIDATOR)
      .email(EMAIL_ADDRESS_IS_INCORRECT_VALIDATOR),
    firstName: string().required(FIRST_NAME_IS_REQUIRED_VALIDATOR),
    lastName: string().required(LAST_NAME_IS_REQUIRED_VALIDATOR),
    phoneNumber: string().matches(
      rePhoneNumber,
      PHONE_NUMBER_IS_INCORRECT_VALIDATOR,
    ),

    city: string().when(
      'facture',
      factureGapsSchema(CITY_IS_REQUIRED_VALIDATOR),
    ),
    companyName: string().when(
      'facture',
      factureGapsSchema(COMPANY_NAME_IS_REQUIRED_VALIDATOR),
    ),
    facture: boolean().required(),
    houseNumber: string().when(
      'facture',
      factureGapsSchema(CHOSE_NUMBER_IS_REQUIRED_VALIDATOR),
    ),
    nip: string().when('facture', {
      is: true,
      otherwise: string(),
      then: string()
        .required(NIP_IS_REQUIRED_VALIDATOR)
        .test('is-nip', NIP_INCORRECT_TEXT, (value) => isValidNip(value)),
    }),
    street: string().when(
      'facture',
      factureGapsSchema(STREET_IS_REQUIRED_VALIDATOR),
    ),
    zipCode: string()
      .when('facture', factureGapsSchema(ZIP_CODE_REQUIRED_VALIDATOR))
      .matches(reZipCode, ZIP_CODE_IS_INCORRECT_VALIDATOR),
  };

  return object().shape(validators);
};

export const makeValidationSchema = defaultMemoize(
  makeValidationSchemaToMemoize,
);
