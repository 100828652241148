// Private
export const _GET_REQUEST = 'Zagrywki/booking/availabilities/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/booking/availabilities/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/booking/availabilities/_GET_FAILURE';

// Public
export const SET_DAY = 'Zagrywki/booking/values/SET_DAY';
export const SET_NUMBER_OF_PLAYERS =
  'Zagrywki/booking/values/SET_NUMBER_OF_PLAYERS';
export const SET_SLOT = 'Zagrywki/booking/values/SET_SLOT';
export const SET_SPACE = 'Zagrywki/booking/values/SET_SPACE';
export const REDIRECT_TO_TERMS = 'Zagrywki/booking/values/REDIRECT_TO_TERMS';
export const SET_DURATION_TIME_AFTER_MIDNIGHT =
  'Zagrywki/booking/values/SET_DURATION_TIME_AFTER_MIDNIGHT';

export const RESET_SPACE_AND_SLOT =
  'Zagrywki/booking/values/RESET_SPACE_AND_SLOT';
export const RESET_ALL = 'Zagrywki/booking/values/RESET_ALL';
