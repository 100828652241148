import { setNumberOfPlayers } from '@Model/booking/actions';
import {
  getActivityDuration,
  getSelectedNumberOfPlayers,
} from '@Model/booking/selector';
import { get as getHappening } from '@Model/happening/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Slider from './Slider.component';
import { ISliderFromDispatch, ISliderFromState } from './Slider.types';

const mapStateToProps = (state: _Store.IState): ISliderFromState => {
  const happening = getHappening(state);

  return {
    activityDuration: getActivityDuration(state),
    maxPeopleCount: (happening && happening.maxNumberOfPeople) || 1,
    peopleCount: getSelectedNumberOfPlayers(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ISliderFromDispatch => ({
  setPeopleCount: (value: number) => dispatch(setNumberOfPlayers(value)),
});

export default connect<
  ISliderFromState,
  ISliderFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Slider);
