import { connect } from 'react-redux';

import { getHappenings } from '@Model/happenings/selectors';
import _Store from '@Store';
import HappeningBox from './HappeningBox.component';
import { IHappeningBoxFromState } from './HappeningBox.types';

const mapStateToProps = (state: _Store.IState): IHappeningBoxFromState => {
  return {
    happenings: getHappenings(state),
  };
};

export default connect<IHappeningBoxFromState, void, void, _Store.IState>(
  mapStateToProps,
)(HappeningBox);
