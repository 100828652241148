// Public
export const START_MOUNTED = 'Zagrywki/start/START_MOUNTED';
export const SET_EXTERNAL_FORM_URL = 'Zagrywki/start/SET_EXTERNAL_FORM_URL';
export const SET_ROOM_URL = 'Zagrywki/start/SET_ROOM_URL';
export const MOUNTED = 'Zagrywki/start/MOUNTED';
export const SET_ROOM = 'Zagrywki/start/SET_ROOM';
export const REDIRECT_TO_TRY_AGAIN = 'Zagrywki/start/REDIRECT_TO_TRY_AGAIN';
export const SET_PARTNER_ID = 'Zagrywki/start/SET_PARTNER_ID';
export const CAPTURE_EVENT_FROM_IFRAME =
  'Zagrywki/start/CAPTURE_EVENT_FROM_IFRAME';
export const SET_PASS_CODE = 'SET_PASS_CODE';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const RUN_FBQ_ACTION = 'RUN_FBQ_ACTION';

// Iframe
export const SET_CURRENT_URL_IFRAME_PROVIDER = 'SET_CURRENT_URL';
export const IFRAME_PROVIDER_LOADER_POSITION_TOP = 'LOADER_POSITION_TOP';
export const RESIZE = 'RESIZE';
export const SET_ENTER_FROM_URL = 'SET_ENTER_FROM_URL';
export const SCROLL_TO = 'SCROLL_TO';
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';

export const allowedActions = [
  IFRAME_PROVIDER_LOADER_POSITION_TOP,
  SET_CURRENT_URL_IFRAME_PROVIDER,
  RESIZE,
];
