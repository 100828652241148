import { happeningsMounted, resetState } from '@Model/happenings/actions';
import { getHappenings } from '@Model/happenings/selectors';
import { MODULE_HAPPENINGS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Happenings from './Happenings.component';
import {
  IHappeningsFromDispatch,
  IHappeningsFromState,
} from './Happenings.types';

const mapStateToProps = (state: _Store.IState): IHappeningsFromState => {
  return {
    isLoading: getLoading(MODULE_HAPPENINGS)(state),
    url: getLocation(state).search,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IHappeningsFromDispatch => ({
  mounted: () => dispatch(happeningsMounted()),
  unmount: () => dispatch(resetState()),
});

export default connect<
  IHappeningsFromState,
  IHappeningsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Happenings);
